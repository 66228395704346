import { createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { URL_OVERVIEW, URL_CUSTOMERS } from '../../../utils/restApiUrls';
import getRange from '../../../utils/getRange';
import { dispatch } from '../../../redux/store';

const ACTIVE_CHECKINS_FETCH_ERROR = 'customers.error.fetchActiveCheckIns';
const CHECKOUT_SUCCESS = 'customers.success.checkout';
const CHECKOUT_ERROR = 'customers.error.checkout';
const ALL_CHECKINS_FETCH_ERROR = 'customers.error.fetchAllCheckIns';
const REVIEWS_FETCH_ERROR = 'customers.error.fetchReviews';

const initialState = {
  isLoading: true,
  isActiveCheckInsUpdating: false,
  isAllCheckInsUpdating: false,
  isReviewsUpdating: false,
  updatingUuids: [],
  error: null,
  alertSuccess: null,
  alertError: null,
  duration: 'month',
  dateRange: null,
  modalOpen: false,
  modalInvalidCheckInOpen: false,
  modalLoading: false,
  coworkId:null,
  orgId:null,
  start: null,
  end: null,
  page: 0,
  count: 0,
  order: 'asc',
  searchQuery: '',
  rowsPerPage: 10,
  totalPages: 0,
  customers: {
    activeCheckIns: null,
    allCheckIns: null,
    reviews: null,
  },
};

const slice = createSlice({
  name: 'coworkCustomers',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    setModalLoading(state, action) {
      state.modalLoading =  action.payload;
    },

    setModalOpen(state, action) {
      state.modalOpen =  action.payload;
    },

    setInvalidCheckInModalOpen(state, action) {
      state.modalInvalidCheckInOpen =  action.payload;
    },

    setCoworkId(state, action) {
      state.coworkId =  action.payload;
    },

    setOrgId(state, action) {
      state.orgId =  action.payload;
    },
    startActiveCheckInsUpdating(state) {
      state.isActiveCheckInsUpdating = true;
      state.customers.activeCheckIns = [];
    },

    startAllCheckInsUpdating(state) {
      state.isAllCheckInsUpdating = true;
      state.customers.allCheckIns = [];
    },

    startReviewsUpdating(state) {
      state.isReviewsUpdating = true;
    },

    startCheckoutUpdating(state, action) {
      const uuid = action.payload;
      state.updatingUuids.push(uuid);
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setAlertSuccess(state, action) {
      state.alertSuccess = action.payload;
    },

    setAlertError(state, action) {
      state.alertError = action.payload;
    },

    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    resetAlertError(state) {
      state.alertError = null;
    },

    loadingDone(state) {
      state.isLoading = false;
      state.isActiveCheckInsUpdating = false;
      state.isAllCheckInsUpdating = false;
      state.isReviewsUpdating = false;
    },

    resetPagination(state, action) {
      const tab = action.payload;
      state.page = 0;
      state.count = 0;
      state.rowsPerPage = 10;
    },

    getActiveCheckInsSuccess(state, action) {
      state.isLoading = false;
      state.isActiveCheckInsUpdating = false;
      // eslint-disable-next-line camelcase
      const { checkins, pagination } = action.payload;
      const { total_items, current_page,per_page } = pagination;
      if (checkins.length) {
        state.customers.activeCheckIns = checkins;
        // eslint-disable-next-line camelcase
        state.page = current_page - 1;
        // eslint-disable-next-line camelcase
        state.count = total_items;
        // eslint-disable-next-line camelcase
        state.rowsPerPage = per_page;
      } else {
        state.count = 0;
        state.customers.activeCheckIns = [];
      }
    },

    getAllCheckInsSuccess(state, action) {
      state.isLoading = false;
      state.isAllCheckInsUpdating = false;
      // eslint-disable-next-line camelcase
      const { checkins, pagination } = action.payload;
      const { total_items, current_page,per_page } = pagination;
      if (checkins.length) {

        state.customers.allCheckIns = checkins;
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        state.count = total_items;
        // eslint-disable-next-line camelcase
      } else {
        state.count = 0;
        state.page = 0;
        state.customers.allCheckIns = [];
      }
    },

    getReviewsSuccess(state, action) {
      state.isLoading = false;
      state.isReviewsUpdating = false;
      const {
        reviews,
         pagination,
      } = action.payload;

      if (reviews?.length) {
        state.customers.reviews = reviews;
        state.page = pagination?.current_page - 1;
        state.count = pagination?.total_items;
        state.rowsPerPage = pagination?.per_page;
        state.totalPages = pagination?.total_pages;
      } else {
        state.count = 0;
        state.customers.reviews = [];
      }
    },

    getActiveCheckoutSuccess(state, action) {
      const uuid = action.payload;
      console.log('uuid', uuid);

      const newUpdatingUuids = state?.updatingUuids?.filter((item) => item !== uuid);
      state.updatingUuids = newUpdatingUuids;

      state.customers.activeCheckIns = state?.customers?.activeCheckIns?.filter((checkIn) => {
        console.log('checkIn', checkIn);
        return checkIn.uuid !== uuid;
      });
    },


    updateDuration(state, action) {
      const { newDuration, tab } = action.payload;
      state.duration = newDuration;
      console.log('tab', tab);
      state.page = 0;
      // if (tab === 'Reviews') {
      //   state.page = 1;
      // } else {
      //   state.page = 0;
      // }
      if (newDuration !== 'custom') {
        state.dateRange = null;
      }
    },

    updatePage(state, action) {
      console.log('redux updatePage', action.payload);
      state.page = action.payload;
      // state.updateTimeStamp = fTimestampNow();
    },

    handleAllCheckInsSearch(state, action) {
      state.searchQuery = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setModalLoading,
  setModalOpen,
  setInvalidCheckInModalOpen,
  updateDuration,
  updatePage,
  resetPagination,
  handleAllCheckInsSearch,
  resetAlertSuccess,
  setCoworkId,
  setOrgId,
  resetAlertError,
} = slice.actions;

export function getActiveCheckIns(durationLabel, page,id) {
  return async () => {
    dispatch(slice.actions.startActiveCheckInsUpdating());
    try {
      const response = await axios.post(URL_CUSTOMERS.checkIns + `?active_checkins=${true}&page=${page + 1}`, {
        // start_date: "2024-08-02",
        // end_date: "2024-08-28",
        ...getRange('day'),
        cowork_ids: id ? [id] : []
      });
      if (response.status) {

        const { data } = response;
        dispatch(slice.actions.getActiveCheckInsSuccess(data?.data));
      } else {
        dispatch(slice.actions.loadingDone());
        dispatch(slice.actions.hasError(response.data.error?.title || ACTIVE_CHECKINS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.title || ACTIVE_CHECKINS_FETCH_ERROR));
      }
    } catch (error) {
      console.log('getActiveCheckIns', error);
      dispatch(slice.actions.loadingDone());
      dispatch(slice.actions.hasError(ACTIVE_CHECKINS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(ACTIVE_CHECKINS_FETCH_ERROR));
    }
  };
}

export function getAllCheckIns(durationLabel, page, search,id) {
  return async () => {
    dispatch(slice.actions.hasError());
    dispatch(slice.actions.setAlertError());
    dispatch(slice.actions.startAllCheckInsUpdating());

    try {
      const response = await axios.post(
          `${URL_CUSTOMERS.checkIns}?page=${page + 1}${search ? `&search_text=${search}` : ''}`,
          {
            ...getRange(durationLabel),
            cowork_ids: id ? [id] : []
          }
      );
      if (response.status) {
        const { data } = response;
        dispatch(slice.actions.getAllCheckInsSuccess(data?.data));
      } else {
        dispatch(slice.actions.loadingDone());
        dispatch(slice.actions.hasError(response.data.error?.title || ALL_CHECKINS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.title || ALL_CHECKINS_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.loadingDone());
      dispatch(slice.actions.hasError(ALL_CHECKINS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(ALL_CHECKINS_FETCH_ERROR));
    }
  };
}

export function getReviews({ durationLabel, page,cowork_id,per_page }) {
  return async () => {
    dispatch(slice.actions.startReviewsUpdating());
    dispatch(slice.actions.hasError());
    dispatch(slice.actions.setAlertError());
    try {
    // , {
    //     params: {
    //       p: page + 1,
    //     ...getRange(durationLabel),
    //     },
    //   }
      const response = await axios.get( `${URL_CUSTOMERS.reviews.replace(':id',cowork_id)}?page=${page + 1}&per_page=${per_page}` );
      if (response.data.success) {
        console.log(response?.data,'response?.data')
        const { data } = response?.data;
        dispatch(slice.actions.getReviewsSuccess(data));
      } else {
        dispatch(slice.actions.loadingDone());
        dispatch(slice.actions.hasError(response.data.error?.title || REVIEWS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.title || REVIEWS_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.loadingDone());
      dispatch(slice.actions.hasError(REVIEWS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(REVIEWS_FETCH_ERROR));
    }
  };
}

export function checkout(uuid) {
  return async () => {
    dispatch(slice.actions.startCheckoutUpdating(uuid));
    try {
      const response = await axios.post(URL_OVERVIEW.checkout, {
        uuid,
      });
      if (response.data.status) {
        // const { data } = response;
        dispatch(slice.actions.getCheckoutSuccess(uuid));
        dispatch(slice.actions.setAlertSuccess(CHECKOUT_SUCCESS));
      } else {
        dispatch(slice.actions.loadingDone());
        dispatch(
          slice.actions.setAlertError(response.data.error?.title || response.data.error?.message || CHECKOUT_ERROR)
        );
      }
    } catch (error) {
      console.log('coworkcustomer checkout', error);
      dispatch(slice.actions.loadingDone());
      dispatch(slice.actions.setAlertError(CHECKOUT_ERROR));
    }
  };
}

export function markInvalidCheckout(uuid,id,active,durationLabel) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.setModalLoading(true));
    try {
      const response = await axios.put(URL_CUSTOMERS.invalidCheckIns, {
        checkin_id: uuid,
      });
      if (response.data.status) {
        // const { data } = response;
        if(active){
          dispatch(slice.actions.getActiveCheckoutSuccess(uuid));
        }


        dispatch(slice.actions.setAlertSuccess(CHECKOUT_SUCCESS));
        const state = getState();
        const { duration, page } = state.coworkCustomers;
        try{
          const response = await axios.post(URL_CUSTOMERS.checkIns + `${active ? `?active_checkins=${active}&` : `?`}page=${page + 1}`, {
            ...(active ? {} :getRange(durationLabel)),
            cowork_ids: id ? [id] : []
          });
          if (response.status) {
            const { data } = response;
            if(active){
              dispatch(slice.actions.getActiveCheckInsSuccess(data?.data));
            }else{
              dispatch(slice.actions.getAllCheckInsSuccess(data?.data));
              // dispatch(getAllCheckIns(durationLabel,page,"",id));
            }

          }
        }catch (error) {
          console.log('getActiveCheckIns', error);
          dispatch(slice.actions.loadingDone());
          dispatch(slice.actions.hasError(ACTIVE_CHECKINS_FETCH_ERROR));
          dispatch(slice.actions.setAlertError(ACTIVE_CHECKINS_FETCH_ERROR));
        }
      } else {
        dispatch(slice.actions.loadingDone());
        dispatch(
            slice.actions.setAlertError(response.data.error?.title || response.data.error?.message || CHECKOUT_ERROR)
        );
      }
    } catch (error) {
      console.log('coworkcustomer checkout', error);
      dispatch(slice.actions.loadingDone());
      dispatch(slice.actions.setAlertError(CHECKOUT_ERROR));
    }finally {
      dispatch(slice.actions.setModalLoading(false));
      dispatch(slice.actions.setInvalidCheckInModalOpen(false));
    }
  };
}
