import {startOfMonth, startOfYear, endOfYear, setYear, addMonths, endOfMonth, addDays} from 'date-fns';
import { fDisplay } from './formatTime';

export default function getRange(durationLabel, dateRange) {
  const NOW = new Date();
  const DATE_FORMAT = 'yyyy-MM-dd';
  const duration = dateRange && dateRange.start ? 'custom' : durationLabel;
  const range = {
    start_date: null,
    end_date: fDisplay(NOW, DATE_FORMAT),
  };
  const lastMonthDate = addMonths(new Date(), -1);
  switch (duration) {
    case 'monthLast':
      range.start_date = startOfMonth(lastMonthDate);
      range.end_date = fDisplay(endOfMonth(lastMonthDate), DATE_FORMAT);
      break;
    case 'month':
      range.start_date = startOfMonth(NOW);
      range.end_date = fDisplay(endOfMonth(NOW), DATE_FORMAT);
      break;
    case 'year':
      range.start_date = startOfYear(NOW);
      range.end_date = fDisplay(endOfYear(NOW), DATE_FORMAT);
      break;
    case 'day':
      range.start_date = NOW;
      break;
    case 'dayNext':
      range.start_date =  fDisplay(addDays(NOW, 1), DATE_FORMAT);
      range.end_date = fDisplay(addDays(NOW, 1), DATE_FORMAT);
      break;
    case 'next11Day':
      range.start_date =  NOW;
      range.end_date = fDisplay(addDays(NOW, 10), DATE_FORMAT);
      break;
    case 'all':
      range.start_date = setYear(startOfYear(NOW), 1970);
      range.end_date = fDisplay(addMonths(NOW, 3), DATE_FORMAT);
      break;
    case 'custom':
    default:
      break;
  }
  try {
    range.start_date = fDisplay(range.start_date, DATE_FORMAT);
  } catch (e) {
    //
  }

  return {
    ...((range.start_date && range) || {}),
  };
}
