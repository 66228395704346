import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import VenueCard from './VenueCard';
import { SkeletonProductItem } from '../../../../components/skeleton';
import {useSelector} from "../../../../redux/store";

VenueCardList.propTypes = {
  spaces: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedId: PropTypes.string,
};

export default function VenueCardList({ isError,spaces,selectedId }) {
  const [displayedSpaces, setDisplayedSpaces] = useState(spaces.slice(0, 10)); // Show first 10 items initially
  const [hasMore, setHasMore] = useState(spaces.length > 10);
  const { isLoading:loading } = useSelector((state) => state.venueOnboardEdit);
  const [isLoading, setIsLoading] = useState(loading);


  useEffect(() => {
    setDisplayedSpaces(spaces.slice(0, 10));
    setHasMore(spaces.length > 10);
  }, [spaces]);

  const loadMoreSpaces = () => {
    setIsLoading(true);
    const currentLength = displayedSpaces.length;
    const newSpaces = spaces.slice(currentLength, currentLength + 10); // Load 10 more items
    setDisplayedSpaces([...displayedSpaces, ...newSpaces]);

    // Check if all spaces are loaded
    if (displayedSpaces.length + newSpaces.length >= spaces.length) {
      setHasMore(false);
    }
    setIsLoading(false);
  };

  const loader = () => {
    return (
      <>
        {([...Array(8)]).map((space, index) =>
          <SkeletonProductItem key={index} />
        )}
      </>
    )
  }
  return (
    <>
      {!isLoading && spaces && !spaces.length && !isError ? (
        <Typography variant="body" sx={{ display: 'block' }}>
          No rooms found!
        </Typography>
      ) : (
        <InfiniteScroll
          dataLength={displayedSpaces.length}
          next={loadMoreSpaces}
          hasMore={hasMore}
          loader={loader}
          style={{ overflow: 'visible' }}
        >
          <Box
            sx={{
              display: 'grid',
              gap: 3,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(4, 1fr)',
              },
            }}
          >
            {displayedSpaces.map((space, index) =>
              space ? (
                <VenueCard
                  key={space.id}
                  space={space}
                  isLinkLoading={selectedId === space.id}
                />
              ) : (
                <SkeletonProductItem key={index} />
              )
            )}
            {loading && [...Array(8)].map((item, index) => (
              <SkeletonProductItem key={index} />
            ))}
          </Box>
        </InfiniteScroll>
      )}
    </>
  );
}
