import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
// config
import { HOST_API } from '../utils/config';
// locale
import i18n from '../locales/i18n';
import useAuth from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "../redux/store";
import {PATH_AUTH} from "../routes/paths";
import {logoutResetReduxStates} from "../pages/settings/settingsSlice";
import {URL_AUTH} from "./restApiUrls";

// ----------------------------------------------------------------------


export const axiosService = axios;

const refreshAxios = axios.create({
    baseURL: HOST_API, // Set this to your API base URL
    timeout: 60 * 1000,
});


export const api = axios.create({
    timeout: 60 * 1000,
});


// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // const org_id = window.localStorage.getItem('org_id');
    const params = { ...(config.params && config.params || {}) };
    params.lang = i18n.language;

    const newConfig = { ...config, params };
    if (accessToken) {
      newConfig.headers.Authorization = `Bearer ${accessToken}`
    }
    // Replace :org_id in the URL with the user's organization ID
    // if (org_id && newConfig.url.includes(":org_id")) {
    //   console.log(newConfig.url, newConfig.url.replace(':org_id', org_id));
    //   newConfig.url = newConfig.url.replace(':org_id', org_id);
    // }
    return newConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  });

axios.interceptors.response.use(
    (response) => {
        // console.log(response,'in interceptors')
        // Check status code and implement actions
        switch (response.status) {
            case 200:
                // console.log('Request successful:', response);
                break;
            case 201:
                // console.log('Resource created:', response);
                break;
            // Add more status codes as needed
            default:
                console.log('Unhandled status code:', response.status);
        }
        return response;
    },
    async (error) => {
        if (error.response) {
            // Handle different status codes for errors
            switch (error.response.status) {
                case 401:
                    const refresh_token = window.localStorage.getItem('refreshToken');
                    if (refresh_token !== undefined || refresh_token !== null ) {
                        try {
                            // Use the separate instance to fetch a new token
                            const response = await refreshAxios.post(URL_AUTH.refreshToken, { refresh_token });
                            console.log('response?.data', response?.data);
                            console.log('response?.data?.success', response?.data?.success);
                            if (response?.data?.success) {
                                // Save new tokens
                                const { access_token, refresh_token } = response.data.data;
                                window.localStorage.setItem('accessToken', access_token);
                                window.localStorage.setItem('refreshToken', refresh_token);

                                // Retry original request
                                error.config.headers.Authorization = `Bearer ${access_token}`;
                                return axios.request(error.config);
                            } else {
                                throw new Error('Invalid refresh token response');
                            }
                        } catch (refreshError) {
                            console.log('Refresh token request failed:', refreshError);
                            // Redirect to login on failure
                            window.localStorage.removeItem('accessToken');
                            window.localStorage.removeItem('refreshToken');
                            window.localStorage.setItem('lwt_ut', 0);
                            window.location.href = PATH_AUTH.login;
                        }
                    } else {
                        console.log('Unauthorized - Redirecting to login...');
                        window.localStorage.removeItem('accessToken');
                        window.localStorage.removeItem('refreshToken');
                        window.localStorage.setItem('lwt_ut', 0);
                        window.location.href = PATH_AUTH.login;
                    }

                    break;
                case 403:
                    console.log('Forbidden - Access denied.');
                    // Implement redirect or user notification
                    break;
                case 404:
                    console.log('Resource not found.');
                    // Handle 404 specific logic
                    break;
                case 500:
                    console.log('Server error - Try again later.');
                    // Handle server errors
                    break;
                default:
                    console.log(`Unhandled error status code: ${error.response.status}`);
            }
        } else {
            console.log('Error without response:', error.message);
        }
        return Promise.reject(error);
    }
);

export default axios;
