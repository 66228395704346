// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';
// hooks
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function BlockContent({className,dark=false}) {
  const { Translate } = useLocales();
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ position:'relative',width: 1, textAlign: { xs: 'center', md: 'left' },...className }}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5" sx={dark ? { color: '#ffffff' }: { color: 'text.secondary' }}>
          <Translate i18nKey='global.selectImageTitle' />
        </Typography>

        <Typography variant="body2"sx={dark ? { color: '#ffffffdb' }: { color: 'text.secondary' }}>
          <Translate i18nKey='venueCreateEdit.dropImage' >
            Drop image here or click
            <Typography
              variant="body2"
              component="span"
              sx={{ color: 'primary.main', textDecoration: 'underline' }}
            >
              browse
            </Typography>
            through your device
          </Translate>
        </Typography>
      </Box>

      {dark ? <Typography variant="body2" sx={{
        color: '#ffffffdb',
        position: 'absolute',
        right: 8,
        bottom: 8
      }}>
        (the image set as the cover image <br/> of your listing is displayed here)
      </Typography> :
        null
      }
    </Stack>
  );
}
