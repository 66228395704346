import React from 'react';
import {Box, Card, Grid, Skeleton} from '@mui/material';

function SkeletonReviews() {
    return (
        <Grid item xs={12}>
            <Card sx={{ px: 4, py: 3 }}>
        <Grid container spacing={6}>
            {Array.from({ length: 4 }).map((_, index) => (
                <Grid item xs={12} key={index}>
                    <Box sx={{ display: 'flex', justifyContent: 'start',gap:'3rem' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Skeleton variant="circular" width={80} height={80} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={120} />
                            <Skeleton variant="text" sx={{ fontSize: '0.5rem' }} width={80} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={120} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={300} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
                        </Box>
                    </Box>
                </Grid>
            ))}
        </Grid>
            </Card></Grid>
    );
}

export default SkeletonReviews;
