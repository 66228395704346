import { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { WrapperVariantContext } from '@mui/x-date-pickers/internals';
// hooks
import useLocales from '../hooks/useLocales';

export default function CustomActionBar({ onAccept, onCancel, actions }) {
  const { Translate } = useLocales();

  const wrapperVariant = useContext(WrapperVariantContext);

  const actionsArray = typeof actions === 'function' ? actions(wrapperVariant) : actions;

  if (actionsArray == null || actionsArray.length === 0) {
    return null;
  }

  return (
    <DialogActions>
      {actionsArray?.map((actionType) => {
        switch (actionType) {
          case 'cancel':
            return (
              <Button
                onClick={() => {
                  onCancel();
                }}
                key={actionType}
              >
                <Translate i18nKey="global.cancel" />
              </Button>
            );

          case 'accept':
            return (
              <Button
                onClick={() => {
                  onAccept();
                }}
                key={actionType}
              >
                <Translate i18nKey="global.ok" />
              </Button>
            );

          default:
            return null;
        }
      })}
    </DialogActions>
  );
}

CustomActionBar.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(['accept', 'cancel', 'clear', 'today'])),
    PropTypes.func,
  ]),
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
