import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, LinearProgress } from '@mui/material';
//
import DropzoneMessage from './DropzoneMessage';
import RejectionFiles from './RejectionFiles';
import MultiFileIsFrontPreview from './MultiFileIsFrontPreview';
import Image from "../Image";
import isString from "lodash/isString";

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));


const RenderProgressIndicator = ({progress}) => {
  return (
      <Box
          sx={{
            position: 'absolute',
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            paddingX: 5, // px-20 equivalent (20 * 4 = 80px)
            backgroundColor: 'rgba(255, 255, 255, 0.6)', // bg-white/60
          }}
      >
        <Typography align="center">Uploading...</Typography>
        <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              width: '200px', // w-4 equivalent (arbitrary width set here)
              height: 8, // h-1 equivalent
              borderRadius: 9999, // rounded-full
              backgroundColor: '#b4bcc3',
            }}
        />
      </Box>
  );
};

// ----------------------------------------------------------------------

UploadMultiFileIsFront.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  uploaded: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onSetIsFront: PropTypes.func,
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadMultiFileIsFront({
  error,
  showPreview = false,
  onRemove,
  onRemoveAll,
  onSetIsFront,
  uploaded,
  helperText,
  sx,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
  });
const uploadFileFront = uploaded?.length ? uploaded.find((item)=> item.is_front === true)?.media_url : null
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          position: 'relative',
          height:'300px',
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        {other.isUploading ?
            <RenderProgressIndicator progress={other?.progress}/> :
              <>
                <input {...getInputProps()} />

                <DropzoneMessage className={ uploadFileFront ? {
                  zIndex:9999,
                  top: 8,
                  left: 8,
                  borderRadius: 1,
                  position: 'absolute',
                  width: 'calc(100% - 16px)',
                  height: 'calc(100% - 16px)',
                  backgroundColor:'#0000007d'
                } : {}} dark={uploadFileFront ? true : false}/>
                {uploadFileFront && (
                    <Image
                        alt="file preview"
                        src={uploadFileFront}
                        sx={{
                          top: 8,
                          left: 8,
                          borderRadius: 1,
                          position: 'absolute',
                          width: 'calc(100% - 16px)',
                          height: 'calc(100% - 16px)',
                        }}
                    />
                )}
              </>

              }

            </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      <MultiFileIsFrontPreview files={uploaded} showPreview={showPreview} onRemove={onRemove} onRemoveAll={onRemoveAll} onSetIsFront={onSetIsFront} />

      {helperText && helperText}
    </Box>
  );
}
