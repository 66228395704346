import { useEffect, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useParams, useLocation, Link as RouterLink } from 'react-router-dom';
import { capitalCase } from 'change-case';
import { Container, Typography, DialogTitle, IconButton, Box, Card, Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getVenues, resetAlertSuccess, resetAlertError } from '../VenuesSlice';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import useLocales from '../../../../hooks/useLocales';
import useResponsive from '../../../../hooks/useResponsive';
import Page from '../../../../components/Page';
import Iconify from '../../../../components/Iconify';
import PageError from '../../../../components/PageError';
import { DialogAnimate } from '../../../../components/animate';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { Search, VenueCardList } from '.';
import useAuth from 'src/hooks/useAuth';
import { ROLE_BASED_DISPLAY } from 'src/config';
import {ROLE} from "../../../../utils/constants";

const BREADCRUMB_LINKS = [
  { name: 'My Venues', href: PATH_DASHBOARD.venues.list, translationKey: 'rooms.myVenues' }
];

export default function VenueList({ version }) {
  const isDesktop = useResponsive('up', 'sm');
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const { Translate, translate: t } = useLocales();

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const isSpaceList = pathname.includes('branch');

  const [searchSpace, setSearchSpace] = useState("");
  const { venues, isLoading, alertSuccess, alertError,linkCalendarSpaceId } = useSelector((state) => state.venueOnboardEdit);

  const linkToMyPlaces = PATH_DASHBOARD.venues.list;
  const linkToNewBranch = `${PATH_DASHBOARD.onboarding.replace(':org_id', user?.organisation_user_details?.
    organisation?.id)}`;
  // const linkToNewSpace = isSpaceList ? `${PATH_DASHBOARD.venues.root}/branch/${branchid}/spaces/new` : '#';

  const filteredVenues = useMemo(
    () => venues.filter(venue => venue.name.toLowerCase().includes(searchSpace.toLowerCase())  || searchSpace == ''),
    [venues, searchSpace]
  )

  useEffect(() => {
    setSearchSpace("");
    if (user?.organisation_user_details?.
      organisation?.id || ROLE_BASED_DISPLAY.BOTH.includes(user?.system_user)) {
      dispatch(getVenues(user?.organisation_user_details?.
        organisation?.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (alertSuccess) {
      enqueueSnackbar(t(alertSuccess), { preventDuplicate: false });
      setTimeout(() => dispatch(resetAlertSuccess()), 50);
    }
    if (alertError) {
      enqueueSnackbar(t(alertError), { variant: 'error', preventDuplicate: false });
      setTimeout(() => dispatch(resetAlertError()), 50);
    }
  }, [alertSuccess, alertError, enqueueSnackbar, dispatch, t]);

  const breadcrumbLinks = useMemo(
    () => [
      {
        name: (
          <Translate i18nKey="venues.venues" count={filteredVenues.length}>
            {{ count: filteredVenues.length }}
          </Translate>
        ),
      },
    ],
    [filteredVenues.length, isLoading]
  );

  return (
    <Page title={t('venues.pageTitle')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Stack direction="row" sx={{ display: 'flex', alignItems: isDesktop ? 'center' : 'start', mb: 1 }}>
          <HeaderBreadcrumbs sx={{ margin: 0 }} heading={t('venues.title')} links={breadcrumbLinks} />
          <Box sx={{ flexGrow: 1 }} />
          {(isDesktop && (user?.system_user == ROLE.USER && user?.organisation_user_details?.role_type == ROLE.ORGANISATION_OWNER )  ) && (
            <Stack direction="row" justifyContent="space-between">
              <Button component={RouterLink} to={linkToNewBranch} variant="contained" size="small">
                + {t('venues.btn-newVenue')}
              </Button>
            </Stack>
          )}
          {!isDesktop && (
            <Stack direction="row" justifyContent="space-between">
              {isSpaceList && (
                <IconButton component={RouterLink} to={linkToMyPlaces} color="inherit" size="medium" sx={{ p: 0 }}>
                  <Iconify icon={'typcn:arrow-back-outline'} width={30} height={30} />
                </IconButton>
              )}
              {/* <IconButton component={RouterLink} to={linkToNewSpace} size="medium" color="primary" sx={{ ml: 2, p: 0 }}>
                <Iconify icon={'fluent:add-circle-16-filled'} width={30} height={30} />
              </IconButton> */}
            </Stack>
          )}
        </Stack>
        {venues.length > 0 && (
          <>
            <Search key={searchSpace} isSpaceList={isSpaceList} initValue={searchSpace} updateFilter={setSearchSpace} />
            {searchSpace && filteredVenues.length === 0 && (
              <PageError
                header="global.notFound"
                error={
                  <Translate i18nKey="venues.error-noResults"  values={{ search: searchSpace }}>
                    No results found for {{ searchSpace }}. Try checking for typos or using complete words.
                  </Translate>
                }
              />
            )}
          </>
        )}
        <VenueCardList isError={venues.length > 0} spaces={filteredVenues} loading={venues.length === 0}  selectedId={linkCalendarSpaceId}/>
      </Container>
    </Page>
  );
}
