import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
import {ROLE} from "../utils/constants";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const { user } = useAuth();
  const currentRole = user?.system_user == ROLE.USER ? user?.organisation_user_details?.role_type : user?.system_user;
  const org_name =  user?.organisation_user_details?.organisation?.name || "Organisation"

  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          {/*You do not have permission to access this page*/}
          Your permission settings don’t allow you to see this data. Please contact {org_name}'s owner to give you access.
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
