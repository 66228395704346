// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
const ROOTS_LETSMEET = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  // login: path(ROOTS_AUTH, '/login'), // old
  login: path('', '/login'),
  signup: path('', '/signup'),
  welcome: path('', '/welcome'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  changePassword: path(ROOTS_AUTH, '/change-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_LETSMEET, '/overview'), // ROOTS_DASHBOARD,
  onboarding: path(ROOTS_LETSMEET, '/onboarding/organization/:org_id/venues/new'), // ROOTS_DASHBOARD,
  dashboard: {
    overview: path(ROOTS_LETSMEET, '/overview'),
    new: path(ROOTS_LETSMEET, '/add-overview'),
    spaces: path(ROOTS_LETSMEET, '/spaces'),
    branchCreate: path(ROOTS_LETSMEET, '/spaces/b/new'),
    spaceCreate: path(ROOTS_LETSMEET, '/spaces/b/:branchname/:branchid/s/new'),
    branchEdit: path(ROOTS_LETSMEET, '/spaces/b/:branchname/:branchid/edit'),
    spaceEdit: path(ROOTS_LETSMEET, '/spaces/b/:branchname/:branchid/s/:spacename/:id/edit')
  },
  calendar: path(ROOTS_LETSMEET, '/calendar'),
  sales: {
    root: path(ROOTS_LETSMEET, '/sales'),
    bookings: path(ROOTS_LETSMEET, '/sales/bookings'),
    coworking: path(ROOTS_LETSMEET, '/sales/coworking'),
  },
  customers: {
    root: path(ROOTS_LETSMEET, '/customers'),
    bookings: path(ROOTS_LETSMEET, '/customers/bookings'),
    coworking: path(ROOTS_LETSMEET, '/customers/coworking'),
    reviews: path(ROOTS_LETSMEET, '/customers/reviews'),
    coworkingVenueCheckIns: path(ROOTS_LETSMEET, '/customers/coworking/:id'),
  },
  organisation: {
    root: path(ROOTS_DASHBOARD, '/organization'),
    list: path(ROOTS_DASHBOARD, '/organization/list'),
    add: path(ROOTS_DASHBOARD, '/organization/:org_id/users/new'),
    addAdmin: path(ROOTS_DASHBOARD, '/organization/users/new')
  },
  venues: {
    root: path(ROOTS_DASHBOARD, '/venues'),
    list: path(ROOTS_DASHBOARD, '/venues/list'),
    newBranch: path(ROOTS_DASHBOARD, '/my-places/branch/new'),
    editBranch: path(ROOTS_DASHBOARD, '/my-places/branch/branchname/:branchid/edit'),
    spaces: path(ROOTS_DASHBOARD, '/my-places/branch/branchname/:branchid/list'),
    newSpace: path(ROOTS_DASHBOARD, '/my-places/branch/branchname/:branchid/spaces/new'),
    editSpace: path(ROOTS_DASHBOARD, '/branch/branchname/:branchid/spaces/spacename/:spaceid/edit')
  },
  pending: path(ROOTS_DASHBOARD, '/pending/venues/list'),
  settings: path(ROOTS_DASHBOARD, '/settings'),
  profile: {
    root:path(ROOTS_DASHBOARD, '/profile'
)
}
};

export const PATH_DOCS = 'https://www.letswork.io/host-with-us';
