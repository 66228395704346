import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { isBefore, addDays, formatDistanceStrict } from 'date-fns';
import enLocale from 'date-fns/locale/en-GB';
import arLocale from 'date-fns/locale/ar-SA';
import ptLocale from 'date-fns/locale/pt';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { TextField, Typography, Box, Stack, Button, DialogTitle, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// utils
import { fDisplay, fFromTo } from '../utils/formatTime';
//
import { FormProvider } from './hook-form';
import { DialogAnimate } from './animate';
import CustomActionBar from './CustomActionBar';
// hooks
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------
const LOCALES = { en: enLocale, ar: arLocale, pt: ptLocale };
// ----------------------------------------------------------------------

DurationFilter.propTypes = {
  init: PropTypes.string,
  onChange: PropTypes.func,
  custom: PropTypes.bool,
  applyDateRange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default function DurationFilter({ init, onChange, custom, applyDateRange, disabled ,addExtras=false}) {
  const { Translate, translate: t } = useLocales();
  const [duration, setDuration] = useState(init);
  const [isOpenModal, setOpenModal] = useState(false);
  const [displayDateRange, setDisplayDateRange] = useState(t('global.customDateRange'));

  const handleChange = (event) => {
    const val = event.target.value;
    setDuration(event.target.value);
    if (duration === 'custom' && val !== 'custom') setDisplayDateRange(t('global.customDateRange'));
    onChange(event.target.value);
    if (event.target.value === 'custom') {
      setOpenModal(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleRangeApply = (data) => {
    // alert('selected');
    setDisplayDateRange(getDisplayDataRange(data.display));
    applyDateRange(data);
    onChange('custom');
    setOpenModal(false);
  };

  const getDisplayDataRange = (duration) => {
    const [bookStartTime, bookEndTime] = duration.split(' to ');
    return (
      <Translate i18nKey="overview.bookDuration" bookStartTime={bookStartTime} bookEndTime={bookEndTime}>
        {{ bookStartTime }} to {{ bookEndTime }}
      </Translate>
    );
  };

  const OPTIONS = [
    { id: 'year', name: 'This Year', translationKey: 'duration.thisYear' },
    { id: 'month', name: 'This Month', translationKey: 'duration.thisMonth' },
    { id: 'monthLast', name: 'Last Month', translationKey: 'duration.lastMonth' },
    { id: 'day', name: 'Today', translationKey: 'duration.today' },
      ...(addExtras ? [{ id: 'dayNext', name: 'Tomorrow', translationKey: 'duration.tomorrow' },
    { id: 'next11Day', name: 'Today plus 10 days', translationKey: 'duration.next11Day' }]:[]),
    { id: 'all', name: 'All', translationKey: 'duration.all' },
  ];
  if (custom) OPTIONS.push({ id: 'custom', name: 'Custom', translationKey: 'duration.custom' });

  return (
    <Stack direction="row">
      {custom && duration === 'custom' && (
        <Typography onClick={() => setOpenModal(true)} sx={{ mr: 2 }}>
          [ {displayDateRange} ]
        </Typography>
      )}
      <TextField
        select
        disabled={disabled}
        value={duration}
        SelectProps={{ native: true }}
        onChange={handleChange}
        sx={{
          '& fieldset': { border: '0 !important' },
          '& select': {
            pl: 1,
            py: 0.5,
            pr: '24px !important',
            typography: 'subtitle2',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: 0.75,
            bgcolor: 'background.neutral',
          },
          '& .MuiNativeSelect-icon': {
            top: 4,
            right: 0,
            width: 20,
            height: 20,
          },
        }}
      >
        {OPTIONS.map((option) => (
          <option key={option.id} value={option.id}>
            {t(option.translationKey)}
          </option>
        ))}
      </TextField>
      <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
        <DialogTitle sx={{ mx: 2 }}>
          <Translate i18nKey="global.customDateRange" />
        </DialogTitle>
        <Box sx={{ mx: 2, mt: 2, mb: 1 }}>
          <DateRangeSelect callback={handleRangeApply} onClose={handleCloseModal} />
        </Box>
      </DialogAnimate>
    </Stack>
  );
}

// ----------------------------------------------------------------------
const FORMAT = 'yyyy-MM-dd';
const TRUE = false;
// ----------------------------------------------------------------------

DateRangeSelect.propTypes = {
  init: PropTypes.object,
  callback: PropTypes.func,
  onClose: PropTypes.func,
};

export function DateRangeSelect({ init, callback, onClose }) {
  const { Translate, translate: t, currentLang } = useLocales();
  const NOW = new Date();
  const range = {
    start: init ? new Date(init.start) : addDays(NOW, -6),
    end: init ? new Date(init.end) : NOW,
  };

  const DateRangeSchema = Yup.object().shape({
    start: Yup.date().required(t('global.error-startDateRequired')),
    end: Yup.date().required(t('global.error-endDateRequired')),
  });

  const methods = useForm({
    resolver: yupResolver(DateRangeSchema),
    defaultValues: range,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const postData = {
      start_date: fDisplay(data.start, FORMAT),
      start: data.start,
      end_date: fDisplay(data.end, FORMAT),
      end: data.end,
      display: fFromTo(data.start, data.end),
    };
    console.log(JSON.stringify(postData, null, 2));
    callback(postData);
    reset();
  };

  const values = watch();

  const daysBetweenDates =
      values.start && values.end
          ? formatDistanceStrict(values.start, values.end, { unit: 'day' }).split(' ')[0]
          : 0;

  const isDateError = isBefore(new Date(values.end), new Date(values.start));
  const is90DaysDateError = values.start && values.end && Number(daysBetweenDates) > 90;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} sx={{ p: 3 }}>
        {
          /* Start time */
          <Controller
            name="start"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={LOCALES[currentLang.value]}
                localeText={LOCALES[currentLang.value]}
              >
                <DatePicker
                  {...field}
                  label={t('global.startDate')}
                  inputFormat="dd/MM/yyyy"
                  components={{
                    ActionBar: CustomActionBar,
                  }}
                  componentsProps={{
                    actionBar: {
                      actions: ['cancel', 'accept'],
                    },
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth  error={!!error}   helperText={error ? error.message : null}/>}
                />
              </LocalizationProvider>
            )}
          />
        }

        {
          /* End time */
          <Controller
            name="end"
            control={control}
            disabled={TRUE}
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={LOCALES[currentLang.value]}
                localeText={LOCALES[currentLang.value]}
              >
                <DatePicker
                  {...field}
                  label={t('global.endDate')}
                  inputFormat="dd/MM/yyyy"
                  components={{
                    ActionBar: CustomActionBar,
                  }}
                  componentsProps={{
                    actionBar: {
                      actions: ['cancel', 'accept'],
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!isDateError || !!is90DaysDateError}
                      helperText={
                        (isDateError && <Translate i18nKey="global.error-endDate" />) ||
                        (is90DaysDateError && <Translate i18nKey="global.error-90DaysDuration" />)
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        }
      </Stack>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onClose}>
          <Translate i18nKey="global.btn-close" />
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={isDateError || is90DaysDateError}
          loadingIndicator="Loading..."
        >
          <Translate i18nKey="sales.btn-apply" />
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
