import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { fDisplay } from '../../../utils/formatTime';
import { URL_PLACES } from '../../../utils/restApiUrls';
import { getWeekday } from '../../../utils/helpers';
//
import { dispatch } from '../../../redux/store';
import {DDR_TYPE, SPACE_TYPE} from "../../../utils/constants";

// ----------------------------------------------------------------------
const DETAILS_FETCH_ERROR = 'roomCreateEdit.error.fetchSpace';
const B_DETAILS_FETCH_ERROR = 'roomCreateEdit.error.fetchBranch';
const A_DETAILS_FETCH_ERROR = 'roomCreateEdit.error.fetchAmenity';
const SETUP_DETAILS_FETCH_ERROR = 'roomCreateEdit.error.fetchSetup';
const UPDATE_ERROR = 'roomCreateEdit.error.save';
const UPDATE_SUCCESS = 'roomCreateEdit.success.save';
const DDR_DELETE_ERROR = 'Failed to delete package!';
const DDR_DELETE_SUCCESS = 'Deleted!';
const M_UPLOAD_SUCCESS = 'roomCreateEdit.success.uploadImage';
const M_UPLOAD_ERROR = 'roomCreateEdit.error.uploadImage';
const M_DELETE_SUCCESS = 'roomCreateEdit.success.deleteImage';
const M_DELETE_ERROR = 'roomCreateEdit.error.deleteImage';
const M_ISFRONT_SUCCESS = 'roomCreateEdit.success.setIsFrontImage';
const M_ISFRONT_ERROR = 'roomCreateEdit.error.setIsFrontImage';
const DEFAULTS = {
  ddr_package: {
    is_ddr: false,
    half_day_hours: 1,
    full_day_hours: 1,
    half_day: [],
    full_day: [],
  },
};
const DEFAULT_START_TIME = '09:00';
const DEFAULT_END_TIME = '18:00';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  error: null,
  alertError: null,
  alertSuccess: null,
  branchid: null,
  branchDetails: null,
  spaceId: null,
  spaceDetails: null,
  amenityOptions: null,
  setupOptions: null,
  isSpaceUpdated: false,
  dateRange: null,
  branch: {
    spaceDesc: null,
    currency: null,
    timings: {},
  },
  space: {
    general: null,
    images: [],
    amenities: [],
    currency: null,
    setupList: [],
    setups: [],
    setupCapacity: {},
    timings: {},
    ddrInfo: null,
  },
};

const slice = createSlice({
  name: 'spaceCreateEdit',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START UPDATING
    startUpdating(state) {
      state.isSpaceUpdated = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Display Alert Success
    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    // Display Alert Error
    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    // Reset Alert Success
    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    // Reset Alert Error
    resetAlertError(state) {
      state.alertError = null;
    },

    // GET BRANCH DETAILS
    getBranchDetailsSuccess(state, action) {
      // const res = action.payload.context ? action.payload.context : action.payload;
      state.branchDetails = action.payload;
    },

    // GET AMENITY OPTIONS
    getSetupOptionsSuccess(state, action) {
      state.setupOptions = action.payload.context ? action.payload.context : action.payload;
    },
    // GET DateRange
    getDateRangeSuccess(state, action) {
      console.log(action.payload, "action.payload");
      state.dateRange = action.payload.context ? action.payload.context : action.payload;
    },

    // GET AMENITY OPTIONS
    getAmenityOptionsSuccess(state, action) {
      state.amenityOptions = action.payload.context ? action.payload.context : action.payload;
    },

    // GET SPACE DETAILS
    getSpaceDetailsSuccess(state, action) {
      // state.isLoading = false;
      state.spaceDetails = action.payload;
    },

    // SET DEFAULTS
    setBranchFormDefaults(state, action) {
      const details = action.payload && action.payload.context ? action.payload.context : action.payload;
      const branchDesc = details?.description || '';
      const currency = details?.currency || 'AED';
      const branchid = details?.id || 0;

      state.branchid = branchid;
      state.branch.spaceDesc = branchDesc;
      state.branch.currency = currency;
    },

    // SET DEFAULTS
    setSpaceFormDefaults(state, action) {
      const details = action.payload ;

      const spaceTypeMap = {
        Meeting_Room: 1,
        Office: 2,
        Creative: 3,
      };
      const general = {
        branch_id: (details.general && details?.general?.branch_detail?.id) || 0,
        spaceId: details?.general?.id || 0,
        spaceName: details?.general?.name || '',
        space_type: details?.general ?  details.general?.space_type : SPACE_TYPE.MEETING_ROOM,
        spaceCapacity: details?.general?.total_capacity || '',
        spaceLocation: details?.general?.location || '',
        spaceArea: details?.general?.area_sqft || '',
        full_refund_before: details.general ? details.general?.cancellation_before_hours || 0 : 24,
        hourly_price: details?.general?.price_per_hour || 0,
        book_before: details.general ? details.general?.book_before || 0 : 30,
        daily_price: details?.general?.price_per_day || 1,
        half_day_price: details?.general?.price_per_half_day || 1,
        full_month_price: details?.general?.price_per_month || 1,
        book_up_to: details?.general?.book_up_to || 0,
        spaceDesc: details?.general?.description || state.branch?.spaceDesc || '',
        bookingStartDate: toYYMMDD(details?.general?.booking_allowed_start_date),
        bookingEndDate: toYYMMDD(details?.general?.booking_allowed_end_date),
      };

      const branchTimingCopy = JSON.parse(JSON.stringify(state.branch.timings));

      const spaceTimings =
            details?.general?.schedule_time_set?.line_items?.length ?
            details?.general?.schedule_time_set?.line_items?.reduce((previousValue, currentValue) => {
          const item = {};
          item[`is_open_${getWeekday(currentValue.day)}`] = currentValue?.status === 'Open' ? true : false;
          item[`open_time_${getWeekday(currentValue.day)}`] = currentValue.start_time;
          item[`close_time_${getWeekday(currentValue.day)}`] = currentValue.end_time;
          return {
            ...previousValue,
            ...item,
          };
        }, {})
            :
        [...Array(7)]
          .map((_, index) => {
            const item = {};
            item[`is_open_${index}`] = true;
            item[`open_time_${index}`] = DEFAULT_START_TIME;
            item[`close_time_${index}`] = DEFAULT_END_TIME;
            return {
              ...item,
            };
          })
          .reduce((prev, curr) => ({
            ...prev,
            ...curr,
          }));
      // init the closed day timings
      [...Array(7)]
        .map((_, index) => {
          const isClosed = !spaceTimings[`is_open_${index}`];
          if (isClosed) {
            spaceTimings[`is_open_${index}`] = false;
            spaceTimings[`open_time_${index}`] = DEFAULT_START_TIME;
            spaceTimings[`close_time_${index}`] = DEFAULT_END_TIME;
          }
          return isClosed;
        });

      const timings = spaceTimings;


      const amenities = details?.general?.amenities?.length ? details?.general?.amenities?.map((x) => x.id) : [];

      const setupCapacity = {
        capacity1: 1,
        capacity2: 1,
        capacity3: 1,
        capacity4: 1,
        capacity5: 1,
        capacity6: 1,
      };
      const setupList =
        details?.general?.space_setup?.length ? details?.general?.space_setup.map((x) => {
          setupCapacity[`capacity${x?.id}`] = x?.capacity;
          return x.id;
        }) : [];

      const images = details?.general?.lets_meet_media || [];

      const IS_DDR_EXISTING = details && (details?.is_ddr || details?.ddr_details && Object.keys(details?.ddr_details).length );
      console.log(IS_DDR_EXISTING,'IS_DDR_EXISTING')
      const ddrInfo =
          IS_DDR_EXISTING && {
            is_ddr: details?.is_ddr || true,
            half_day_hours: details?.ddr_details?.half_day_hours_for_ddr_package,
            full_day_hours: details?.ddr_details?.full_day_hours_for_ddr_package,

            // Filter for 'half_day' packages and map them
            half_day: details?.ddr_details?.packages.length
                ? details?.ddr_details?.packages
                    .filter((item) => item.package_type === DDR_TYPE.HALF_DAY)  // Filter for half_day
                    .map((item, index) => ({
                      id: item.id,
                      index,
                      name: item.description,
                      minimum_capacity: item.minimum_persons,
                      description: item.description,
                      price: item.price_per_person,
                      currency: details?.general?.venue_currency,
                      ddr_type: DDR_TYPE.HALF_DAY,
                    }))
                : [],

            // Filter for 'full_day' packages and map them
            full_day: details?.ddr_details?.packages.length
                ? details?.ddr_details?.packages
                    .filter((item) => item.package_type === DDR_TYPE.FULL_DAY)  // Filter for full_day
                    .map((item, index) => ({
                      id: item.id,
                      index,
                      name: item.description,
                      minimum_capacity: item.minimum_persons,
                      description: item.description,
                      price: item.price_per_person,
                      currency:  details?.general?.venue_currency,
                      ddr_type: DDR_TYPE.FULL_DAY,
                    }))
                : [],
          } || DEFAULTS.ddr_package;


      state.spaceId = details?.general?.id || 0;
      state.space.currency = details?.general?.venue_currency || state?.branch?.currency;
      state.space.general = general;
      state.space.images = images;
      state.space.amenities = amenities;
      state.space.setupList = setupList;
      state.space.setups = details?.general?.space_setup;
      state.space.setupCapacity = setupCapacity;
      state.space.timings = timings;
      state.space.ddrInfo = ddrInfo;
      state.isLoading = false;
    },

    // CREATE BRANCH DETAILS
    createEditSpaceSuccess(state, action) {
      // state.branch.isUpdated = true;
      const details= action.payload && action.payload.context ? action.payload.context : action.payload;
      console.log('b4 final set create or edit BranchSuccess');
      state.spaceId = details?.space?.id || 0;
      console.log('create or edit BranchSuccess');
      //
      // console.log(JSON.stringify(details,null,2));
    },

    removeMediaSuccess(state, action) {
      const imageId = action.payload;
      console.log('removeMediaSuccess...', imageId);
      const { space } = state;
      // const { images } = space;
      const filteredItems = state.space.images?.filter((_file) => _file.id !== imageId);
      console.log(JSON.stringify(filteredItems, null, 2));
      space.images = filteredItems;
      state.space = space;
    },

    updateMediaSuccess(state) {
      // const imageId = action.payload;
      const imageUpdatedIndex = -1;
      // const filteredItems = state.space.images?.filter((_file, index) => {
      //   const result = _file.id !== imageId;
      //   if (!result) {
      //     imageUpdatedIndex = index;
      //   }
      //   return result;
      // });
      if (imageUpdatedIndex > -1) state.space.images[imageUpdatedIndex].is_front = 1;
    },

    // BRANCH UPDATED - REFETCHed DETAILS
    spaceUpdateSuccess(state) {
      state.isSpaceUpdated = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setSpaceFormDefaults, resetAlertSuccess, resetAlertError ,setAlertError,hasError} = slice.actions;

// ----------------------------------------------------------------------

export function refreshSpaceDetails(branchId, spaceId) {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.spacedetails, {
        params: { branch_id: branchId, space_id: spaceId, no_currency_change: true },
      });
      if (response.data.status) {
        const getData = response.data.context;
        dispatch(slice.actions.getSpaceDetailsSuccess(getData));
        dispatch(slice.actions.setSpaceFormDefaults(getData));
        dispatch(slice.actions.spaceUpdateSuccess());
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || DETAILS_FETCH_ERROR)
        );
      }
    } catch (error) {
      console.log('refreshSpaceDetails', error);
      dispatch(slice.actions.hasError(DETAILS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(DETAILS_FETCH_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function refreshSpaceDetailsDDR(branchId, spaceId) {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.spacedetails, {
        params: { branch_id: branchId, space_id: spaceId, no_currency_change: true },
      });
      if (response.data.status) {
        const getData = response.data.context;
        dispatch(slice.actions.getSpaceDetailsSuccess(getData));
        dispatch(slice.actions.setSpaceFormDefaults(getData));
        dispatch(slice.actions.ddrUpdateSuccess());
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || DETAILS_FETCH_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(DETAILS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(DETAILS_FETCH_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function getBranchDetails(branchId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_PLACES.branchdetails, {
        params: { branch_id: branchId },
      });
      if (response.data.status) {
        const getData = response.data.context;
        dispatch(slice.actions.getBranchDetailsSuccess(getData));
        dispatch(slice.actions.setBranchFormDefaults(getData));
      } else {
        dispatch(slice.actions.hasError(response.data.error?.title || B_DETAILS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.title || B_DETAILS_FETCH_ERROR));
      }
    } catch (error) {
      console.log('getBranchDetails', error);
      dispatch(slice.actions.hasError(B_DETAILS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(B_DETAILS_FETCH_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function getSpaceDetails(branchId, spaceId,org_id,setLoading) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const API_URL = `${(`${URL_PLACES.space}`).replace(':org_id',org_id).replace(':venue_id', branchId)}${spaceId?`${spaceId}/`:''}`;
      const response = await axios.get(API_URL);
      console.log(response);
      if (response.data.success) {
        const getData = response.data.data;
        dispatch(slice.actions.getSpaceDetailsSuccess(getData));
        dispatch(slice.actions.setSpaceFormDefaults(getData));
      } else {
        dispatch(slice.actions.hasError(response.data.error?.title || DETAILS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.title || DETAILS_FETCH_ERROR));
      }
    } catch (error) {
      console.log('getSpaceDetails', error);
      dispatch(slice.actions.hasError(DETAILS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(DETAILS_FETCH_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function getAmenityOptions(branchid) {
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.get(`${URL_PLACES.amenityOptions}${branchid}/`);
      if (response.data.status) {
        dispatch(slice.actions.getAmenityOptionsSuccess(response.data.context));
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || A_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      console.log('getAmenityOptions', error);
      dispatch(slice.actions.setAlertError(A_DETAILS_FETCH_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function getSetupOptions() {
  return async () => {
    // dispatch(slice.actions.startSpaceLoading());
    try {
      const response = await axios.get(URL_PLACES.setupOptions);
      if (response.data.status) {
        dispatch(slice.actions.getSetupOptionsSuccess(response.data.context));
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || SETUP_DETAILS_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      console.log('getSetupOptions', error);
      dispatch(slice.actions.setAlertError(SETUP_DETAILS_FETCH_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function createEditSpace(details) {
  return async () => {
    try {
      const response = await axios.post(URL_PLACES.createSpace, details);
      if (response.data.status) {
        dispatch(slice.actions.createEditSpaceSuccess(response.data.context));
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(UPDATE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || UPDATE_ERROR)
        );
      }
    } catch (error) {
      console.log('createEditSpace', error);
      dispatch(slice.actions.setAlertError(UPDATE_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function uploadMedia(file, branchId, spaceId) {
  return async () => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        params: {
          branch_id: branchId,
          space_id: spaceId,
        },
      };
      const response = await axios.post(URL_PLACES.media, formData, config);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_UPLOAD_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || M_UPLOAD_ERROR)
        );
      }
    } catch (error) {
      console.log('uploadMedia', error);
      dispatch(slice.actions.setAlertError(M_UPLOAD_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function removeMedia(id, branchId, spaceId) {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.media, { params: { image_id: id, is_delete: 1, branch_id: branchId, space_id: spaceId } });
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_DELETE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || M_DELETE_ERROR)
        );
      }
    } catch (error) {
      console.log('removeMedia', error);
      dispatch(slice.actions.setAlertError(M_DELETE_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function toggleIsFrontMedia(id, branchId, spaceId) {
  return async () => {
    try {
      const response = await axios.get(URL_PLACES.media, {
        params: { image_id: id, is_front: 1, branch_id: branchId, space_id: spaceId },
      });
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(M_ISFRONT_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || M_ISFRONT_ERROR)
        );
      }
    } catch (error) {
      console.log('toggleIsFrontMedia', error);
      dispatch(slice.actions.setAlertError(M_ISFRONT_ERROR));
    }
  };
}

export function ddrPackage(data, branchId, spaceId) {
  return async () => {
    try {
      const response = await axios.post(`${URL_PLACES.ddrPackage}${spaceId}/`, data, {
        params: { branch_id: branchId, space_id: spaceId },
      });
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(UPDATE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || UPDATE_ERROR)
        );
      }
    } catch (error) {
      console.log('ddrPackage', error);
      dispatch(slice.actions.setAlertError(UPDATE_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function getDateRangeBooking(
  spaceid,
  startDate,
  endDate,
  ddrPackage = null
) {
  console.log("Inn");
  return async () => {
    // store.dispatch(slice.actions.resetErrors());
    // store.dispatch(slice.actions.startDateRangeLoading());
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        URL_PLACES.dateRangeBooking,
        {
          params: {
            space_id: spaceid,
            start: startDate,
            end: endDate,
            ...(ddrPackage && { ddr_id: ddrPackage.ddr_id }),
            ...(ddrPackage && { ddr_type: ddrPackage.ddr_type }),
          },
        }
      );
      if (response.data.status) {
        const { data } = response;
        console.log(data, "data");
        dispatch(slice.actions.getDateRangeSuccess(data));
      } else {
        // store.dispatch(
        //   slice.actions.hasError(
        //     response.data.error?.message ||
        //       response.data.error?.title ||
        //       SPACE_FETCH_ERROR // TODO: change error
        //   )
        // );
        // store.dispatch(slice.actions.stopDateRangeLoading());
      }
    } catch (error) {
      // store.dispatch(slice.actions.hasError(SPACE_FETCH_ERROR)); // TODO: change error
      // store.dispatch(slice.actions.stopDateRangeLoading());
    }
  };
}

// ----------------------------------------------------------------------

export function ddrPackageRemove(id, branchId, spaceId) {
  return async () => {
    try {
      const response = await axios.get(`${URL_PLACES.ddrPackageDelete}${id}/`, {
        params: { branch_id: branchId, space_id: spaceId },
      });
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(DDR_DELETE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || DDR_DELETE_ERROR)
        );
      }
    } catch (error) {
      console.log('ddrPackageRemove', error);
      dispatch(slice.actions.setAlertError(DDR_DELETE_ERROR));
    }
  };
}

// ----------------------------------------------------------------------
//              helper
// ----------------------------------------------------------------------

export function toHHmm(dateString) {
  // dateString expected format: 06:00PM
  const newDate = new Date(`2020/01/01 ${dateString.slice(0, 5)} ${dateString.slice(5, 7)}`);
  return fDisplay(newDate, 'HH:mm');
}


export function toYYMMDD(dateString) {
  const dateStr = dateString;
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}
