import { format, parseISO, addDays, addMonths, addWeeks, getTime, formatDistanceToNow } from 'date-fns';
import enLocale from 'date-fns/locale/en-GB';
import arLocale from 'date-fns/locale/ar-SA';
import ptLocale from 'date-fns/locale/pt';
// ----------------------------------------------------------------------

const LOCALES = { en: enLocale, ar: arLocale, pt: ptLocale };

export function fDate(date, locale = 'en') {
  return format(new Date(date), 'dd MMMM yyyy', { locale: LOCALES[locale] });
}

export function fDateShort(date, locale = 'en') {
  return format(new Date(date), 'dd MMM yyyy', { locale: LOCALES[locale] })
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fTimestampNow() {
  return getTime(new Date());
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fParseDateTime(date, formatStr, locale = 'en') { // assuming backend date 2022-04-12T08:12
  try{
    const dateStr = date?.slice(0,19);
    const parsed = parseISO(dateStr);
    return format(parsed, formatStr || 'dd/MM/yyyy HH:mm', { locale: LOCALES[locale] });
  } catch(e){
    return date;
  }
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
export function fDisplay(date, formatStr) {
  return format(new Date(date), formatStr);
}
export function fCal(date) {
  const timezone =  (format(new Date(date), 'OOOO')).slice(3);
  const dateTimeEST = format(new Date(date), `yyyy-MM-dd'T'00:00:00${timezone}'`);
  return dateTimeEST;
}

export function fToNextMonthCal(date, skipFormat) {
  const nextMonth = addMonths(new Date(date), 1);
  if(skipFormat) return nextMonth;
  return fCal(nextMonth);
}

export function fToNextWeekCal(date, skipFormat) {
  const nextWeek = addWeeks(new Date(date), 1);
  if(skipFormat) return nextWeek;
  return fCal(nextWeek);
}

export function fToNextDayCal(date, skipFormat) {
  const nextDay = addDays(new Date(date), 1);
  if(skipFormat) return nextDay;
  return fCal(nextDay);
}


export function fFromTo(from, to) {
  return `${format(from, `dd-MM-yyyy`)} to ${format(to, `dd-MM-yyyy`)}`
}


export function formatTimestampToDate(timestamp) {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  // Format the date using locale string and remove any commas
  const formattedDate = date.toLocaleString('en-GB', options).replace(',', '');

  // Replace the space between date and time with ' at '
  return formattedDate.replace(' ', ' at ');
}

export function formatDateString(dateString) {
  // Parse the date string directly into a Date object
  const date = new Date(dateString);

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  // Format the date using locale string and remove any commas
  const formattedDate = date.toLocaleString('en-GB', options).replace(',', '');

  // Replace the space between date and time with ' at '
  return formattedDate.replace(' ', ' at ');
}

export function formatString(input) {
  return input.replace('_', ' ');
}

export function formatTimeHHmm(input) {
  const formattedTime = input.slice(0, 5);
  return formattedTime
}

export function formatTimeTo12Hour(time) {
  // Split the time string into components
  if (time ===  undefined || time === null ) return ''
  let [hours, minutes, seconds] = time.split(':');

  // Convert hours to number
  hours = parseInt(hours);

  // Determine AM or PM suffix
  let ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12; // Convert '0' to '12' for midnight

  // Return the formatted time string
  return `${hours}:${minutes} ${ampm}`;
}

// export function formatDate(isoString) {
//
//   const options = {
//     year: 'numeric',
//     month: 'short',
//     day: 'numeric',
//     timeZone: 'UTC' // Use UTC to avoid time zone shifts
//   };
//
//   const formattedDate = isoString.toLocaleDateString('en-US', options);
//   const formattedTime = isoString.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true});
//   return `${formattedDate} at ${formattedTime}`;
// }

export function formatDate(isoString) {
  const date = new Date(isoString);

  // Get the day, month, and year values
  const day = String(date.getUTCDate()).padStart(2, '0'); // Adds leading zero if needed
  const month = date.toLocaleString('en-US', { month: 'short'}); // Get short month name
  const year = date.getUTCFullYear(); // Get full year

  // Format the time (12-hour format)
  const formattedTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  // Return formatted date and time in the format 'dd MMM yyyy at hh:mm AM/PM'
  return `${day} ${month} ${year} at ${formattedTime}`;
}

export function formatTimeToLocal(value) {
  const utcDateString = value?.endsWith('Z') ? true : false;
  let utcDate = new Date()
  if(utcDateString){
    utcDate = new Date(value); // UTC date
  }else{
    utcDate = new Date(`${value}:00Z`); // UTC date
  }
  const localDate = new Date(utcDate.toString());
  return formatDate(localDate);
}

export function addAtInTime(value){
  if(value){
    const date = value.split(' ')[0]
    const time = value.split(' ')[1]
    if(time){
      const timeFormated = formatTimeTo12Hour(time)
      return `${date} at ${timeFormated}`;
    }
    return value.replace(' ', ' at ');
  }

}
