// @mui
import { SvgIcon } from '@mui/material';

// ----------------------------------------------------------------------

// GeneralIcon
export function GeneralIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 18">
      <path d="M17.3337 16.7083H0.666992C0.325326 16.7083 0.0419922 16.9916 0.0419922 17.3333C0.0419922 17.675 0.325326 17.9583 0.666992 17.9583H17.3337C17.6753 17.9583 17.9587 17.675 17.9587 17.3333C17.9587 16.9916 17.6753 16.7083 17.3337 16.7083Z"/>
      <path opacity="0.4" d="M13.1667 0.666687H4.83333C2.33333 0.666687 1.5 2.15835 1.5 4.00002V17.3334H16.5V4.00002C16.5 2.15835 15.6667 0.666687 13.1667 0.666687Z"/>
      <path d="M7.33301 13.375H4.83301C4.49134 13.375 4.20801 13.0917 4.20801 12.75C4.20801 12.4083 4.49134 12.125 4.83301 12.125H7.33301C7.67467 12.125 7.95801 12.4083 7.95801 12.75C7.95801 13.0917 7.67467 13.375 7.33301 13.375Z"/>
      <path d="M13.167 13.375H10.667C10.3253 13.375 10.042 13.0917 10.042 12.75C10.042 12.4083 10.3253 12.125 10.667 12.125H13.167C13.5087 12.125 13.792 12.4083 13.792 12.75C13.792 13.0917 13.5087 13.375 13.167 13.375Z"/>
      <path d="M7.33301 9.625H4.83301C4.49134 9.625 4.20801 9.34167 4.20801 9C4.20801 8.65833 4.49134 8.375 4.83301 8.375H7.33301C7.67467 8.375 7.95801 8.65833 7.95801 9C7.95801 9.34167 7.67467 9.625 7.33301 9.625Z"/>
      <path d="M13.167 9.625H10.667C10.3253 9.625 10.042 9.34167 10.042 9C10.042 8.65833 10.3253 8.375 10.667 8.375H13.167C13.5087 8.375 13.792 8.65833 13.792 9C13.792 9.34167 13.5087 9.625 13.167 9.625Z"/>
      <path d="M7.33301 5.875H4.83301C4.49134 5.875 4.20801 5.59167 4.20801 5.25C4.20801 4.90833 4.49134 4.625 4.83301 4.625H7.33301C7.67467 4.625 7.95801 4.90833 7.95801 5.25C7.95801 5.59167 7.67467 5.875 7.33301 5.875Z"/>
      <path d="M13.167 5.875H10.667C10.3253 5.875 10.042 5.59167 10.042 5.25C10.042 4.90833 10.3253 4.625 10.667 4.625H13.167C13.5087 4.625 13.792 4.90833 13.792 5.25C13.792 5.59167 13.5087 5.875 13.167 5.875Z"/>
    </SvgIcon>
  );
}


export function SwitchIcon(props) {
    return (
        <div
            style={{
                width:props.width,
                height:props.height,
                transition: 'transform 0.3s ease', // Add smooth transition
                transform: `rotate(${props.transform}deg)`, // Apply rotation
            }}
        >
            <svg aria-hidden="true" height="20" viewBox="0 0 16 16" version="1.1" width="20" data-view-component="true"
                 className="octicon octicon-sync">
                <path
                    d="M1.705 8.005a.75.75 0 0 1 .834.656 5.5 5.5 0 0 0 9.592 2.97l-1.204-1.204a.25.25 0 0 1 .177-.427h3.646a.25.25 0 0 1 .25.25v3.646a.25.25 0 0 1-.427.177l-1.38-1.38A7.002 7.002 0 0 1 1.05 8.84a.75.75 0 0 1 .656-.834ZM8 2.5a5.487 5.487 0 0 0-4.131 1.869l1.204 1.204A.25.25 0 0 1 4.896 6H1.25A.25.25 0 0 1 1 5.75V2.104a.25.25 0 0 1 .427-.177l1.38 1.38A7.002 7.002 0 0 1 14.95 7.16a.75.75 0 0 1-1.49.178A5.5 5.5 0 0 0 8 2.5Z"></path>
            </svg>
        </div>
    );
}




// RibbonStarIcon
export function RibbonStarIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 20">
      <path opacity="0.4" d="M18 7C18 8.45 17.57 9.78001 16.83 10.89C15.75 12.49 14.04 13.62 12.05 13.91C11.71 13.97 11.36 14 11 14C10.64 14 10.29 13.97 9.95 13.91C7.96 13.62 6.25 12.49 5.17 10.89C4.43 9.78001 4 8.45 4 7C4 3.13 7.13 0 11 0C14.87 0 18 3.13 18 7Z"/>
      <path d="M20.2501 16.47L18.6001 16.86C18.2301 16.95 17.9401 17.23 17.8601 17.6L17.5101 19.07C17.3201 19.87 16.3001 20.11 15.7701 19.48L11.0001 14L6.23008 19.49C5.70008 20.12 4.68008 19.88 4.49008 19.08L4.14008 17.61C4.05008 17.24 3.76008 16.95 3.40008 16.87L1.75008 16.48C0.990079 16.3 0.720079 15.35 1.27008 14.8L5.17008 10.9C6.25008 12.5 7.96008 13.63 9.95008 13.92C10.2901 13.98 10.6401 14.01 11.0001 14.01C11.3601 14.01 11.7101 13.98 12.0501 13.92C14.0401 13.63 15.7501 12.5 16.8301 10.9L20.7301 14.8C21.2801 15.34 21.0101 16.29 20.2501 16.47Z"/>
      <path d="M11.58 3.98L12.17 5.15999C12.25 5.31999 12.46 5.48 12.65 5.51L13.72 5.68999C14.4 5.79999 14.56 6.3 14.07 6.79L13.24 7.61998C13.1 7.75998 13.02 8.03 13.07 8.23L13.31 9.26C13.5 10.07 13.07 10.39 12.35 9.95998L11.35 9.36998C11.17 9.25998 10.87 9.25998 10.69 9.36998L9.68997 9.95998C8.96997 10.38 8.53997 10.07 8.72997 9.26L8.96997 8.23C9.00997 8.04 8.93997 7.75998 8.79997 7.61998L7.96997 6.79C7.47997 6.3 7.63997 5.80999 8.31997 5.68999L9.38997 5.51C9.56997 5.48 9.77997 5.31999 9.85997 5.15999L10.45 3.98C10.74 3.34 11.26 3.34 11.58 3.98Z"/>
    </SvgIcon>
  );
}

// BellIcon
export function BellIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 20">
      <path opacity="0.4" d="M15.3399 12.49L14.3399 10.83C14.1299 10.46 13.9399 9.76 13.9399 9.35V6.82C13.9399 3.56 11.2899 0.9 8.01994 0.9C4.74994 0.9 2.09994 3.56 2.09994 6.82V9.35C2.09994 9.76 1.90994 10.46 1.69994 10.82L0.689943 12.49C0.289943 13.16 0.199943 13.9 0.449943 14.58C0.689943 15.25 1.25994 15.77 1.99994 16.02C3.93994 16.68 5.97994 17 8.01994 17C10.0599 17 12.0999 16.68 14.0399 16.03C14.7399 15.8 15.2799 15.27 15.5399 14.58C15.7999 13.89 15.7299 13.13 15.3399 12.49Z"/>
      <path d="M10.25 1.32C9.56005 1.05 8.81005 0.9 8.02005 0.9C7.24005 0.9 6.49005 1.04 5.80005 1.32C6.23005 0.51 7.08005 0 8.02005 0C8.97005 0 9.81005 0.51 10.25 1.32Z"/>
      <path d="M10.8301 18.01C10.4101 19.17 9.30005 20 8.00005 20C7.21005 20 6.43005 19.68 5.88005 19.11C5.56005 18.81 5.32005 18.41 5.18005 18C5.31005 18.02 5.44005 18.03 5.58005 18.05C5.81005 18.08 6.05005 18.11 6.29005 18.13C6.86005 18.18 7.44005 18.21 8.02005 18.21C8.59005 18.21 9.16005 18.18 9.72005 18.13C9.93005 18.11 10.1401 18.1 10.3401 18.07C10.5001 18.05 10.6601 18.03 10.8301 18.01Z"/>
    </SvgIcon>
  );
}

// CalendarTimingIcon
export function CalendarTimingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 21">
      <path d="M13.75 2.56V1C13.75 0.59 13.41 0.25 13 0.25C12.59 0.25 12.25 0.59 12.25 1V2.5H5.74998V1C5.74998 0.59 5.40998 0.25 4.99998 0.25C4.58998 0.25 4.24998 0.59 4.24998 1V2.56C1.54998 2.81 0.239985 4.42 0.039985 6.81C0.019985 7.1 0.259985 7.34 0.539985 7.34H17.46C17.75 7.34 17.99 7.09 17.96 6.81C17.76 4.42 16.45 2.81 13.75 2.56Z"/>
      <path opacity="0.4" d="M17 8.84C17.55 8.84 18 9.29 18 9.84V16C18 19 16.5 21 13 21H5C1.5 21 0 19 0 16V9.84C0 9.29 0.45 8.84 1 8.84H17Z"/>
      <path d="M5.5 14C5.24 14 4.98 13.89 4.79 13.71C4.61 13.52 4.5 13.26 4.5 13C4.5 12.74 4.61 12.48 4.79 12.29C5.07 12.01 5.51 11.92 5.88 12.08C6.01 12.13 6.12 12.2 6.21 12.29C6.39 12.48 6.5 12.74 6.5 13C6.5 13.26 6.39 13.52 6.21 13.71C6.02 13.89 5.76 14 5.5 14Z"/>
      <path d="M9 14C8.74 14 8.48 13.89 8.29 13.71C8.11 13.52 8 13.26 8 13C8 12.74 8.11 12.48 8.29 12.29C8.38 12.2 8.49 12.13 8.62 12.08C8.99 11.92 9.43 12.01 9.71 12.29C9.89 12.48 10 12.74 10 13C10 13.26 9.89 13.52 9.71 13.71C9.66 13.75 9.61 13.79 9.56 13.83C9.5 13.87 9.44 13.9 9.38 13.92C9.32 13.95 9.26 13.97 9.2 13.98C9.13 13.99 9.07 14 9 14Z"/>
      <path d="M12.5 14C12.24 14 11.98 13.89 11.79 13.71C11.61 13.52 11.5 13.26 11.5 13C11.5 12.74 11.61 12.48 11.79 12.29C11.89 12.2 11.99 12.13 12.12 12.08C12.3 12 12.5 11.98 12.7 12.02C12.76 12.03 12.82 12.05 12.88 12.08C12.94 12.1 13 12.13 13.06 12.17C13.11 12.21 13.16 12.25 13.21 12.29C13.39 12.48 13.5 12.74 13.5 13C13.5 13.26 13.39 13.52 13.21 13.71C13.16 13.75 13.11 13.79 13.06 13.83C13 13.87 12.94 13.9 12.88 13.92C12.82 13.95 12.76 13.97 12.7 13.98C12.63 13.99 12.56 14 12.5 14Z"/>
      <path d="M5.5 17.5C5.37 17.5 5.24 17.47 5.12 17.42C4.99 17.37 4.89 17.3 4.79 17.21C4.61 17.02 4.5 16.76 4.5 16.5C4.5 16.24 4.61 15.98 4.79 15.79C4.89 15.7 4.99 15.63 5.12 15.58C5.3 15.5 5.5 15.48 5.7 15.52C5.76 15.53 5.82 15.55 5.88 15.58C5.94 15.6 6 15.63 6.06 15.67C6.11 15.71 6.16 15.75 6.21 15.79C6.39 15.98 6.5 16.24 6.5 16.5C6.5 16.76 6.39 17.02 6.21 17.21C6.16 17.25 6.11 17.3 6.06 17.33C6 17.37 5.94 17.4 5.88 17.42C5.82 17.45 5.76 17.47 5.7 17.48C5.63 17.49 5.57 17.5 5.5 17.5Z"/>
      <path opacity="0.4" d="M9 17.5C8.74 17.5 8.48 17.39 8.29 17.21C8.11 17.02 8 16.76 8 16.5C8 16.24 8.11 15.98 8.29 15.79C8.66 15.42 9.34 15.42 9.71 15.79C9.89 15.98 10 16.24 10 16.5C10 16.76 9.89 17.02 9.71 17.21C9.52 17.39 9.26 17.5 9 17.5Z"/>
      <path opacity="0.4" d="M12.5 17.5C12.24 17.5 11.98 17.39 11.79 17.21C11.61 17.02 11.5 16.76 11.5 16.5C11.5 16.24 11.61 15.98 11.79 15.79C12.16 15.42 12.84 15.42 13.21 15.79C13.39 15.98 13.5 16.24 13.5 16.5C13.5 16.76 13.39 17.02 13.21 17.21C13.02 17.39 12.76 17.5 12.5 17.5Z"/>
    </SvgIcon>
  );
}

// RippedPaperIcon
export function RippedPaperIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 20">
      <path opacity="0.4" d="M17 5.04V14.96C17 16.48 16.86 17.56 16.5 18.33C16.5 18.34 16.49 18.36 16.48 18.37C16.26 18.65 15.97 18.79 15.63 18.79C15.1 18.79 14.46 18.44 13.77 17.7C12.95 16.82 11.69 16.89 10.97 17.85L9.96002 19.19C9.56002 19.73 9.03 20 8.5 20C7.97 20 7.43998 19.73 7.03998 19.19L6.02002 17.84C5.31002 16.89 4.05999 16.82 3.23999 17.69L3.22998 17.7C2.09998 18.91 1.10002 19.09 0.52002 18.37C0.51002 18.36 0.5 18.34 0.5 18.33C0.14 17.56 0 16.48 0 14.96V5.04C0 3.52 0.14 2.44 0.5 1.67C0.5 1.66 0.50002 1.65 0.52002 1.64C1.09002 0.91 2.09998 1.09 3.22998 2.3L3.23999 2.31C4.05999 3.18 5.31002 3.11 6.02002 2.16L7.03998 0.81C7.43998 0.27 7.97 0 8.5 0C9.03 0 9.56002 0.27 9.96002 0.81L10.97 2.15C11.69 3.11 12.95 3.18 13.77 2.3C14.46 1.56 15.1 1.21 15.63 1.21C15.97 1.21 16.26 1.36 16.48 1.64C16.5 1.65 16.5 1.66 16.5 1.67C16.86 2.44 17 3.52 17 5.04Z"/>
      <path d="M13 9H5C4.59 9 4.25 8.66 4.25 8.25C4.25 7.84 4.59 7.5 5 7.5H13C13.41 7.5 13.75 7.84 13.75 8.25C13.75 8.66 13.41 9 13 9Z"/>
      <path d="M11 12.5H5C4.59 12.5 4.25 12.16 4.25 11.75C4.25 11.34 4.59 11 5 11H11C11.41 11 11.75 11.34 11.75 11.75C11.75 12.16 11.41 12.5 11 12.5Z"/>
    </SvgIcon>
  );
}

// HotCupIcon
export function HotCupIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path opacity="0.6" d="M13.8253 7.72513V9.00013H0.666992V7.72513C0.666992 5.7918 2.24199 4.2168 4.17533 4.2168H10.317C12.2503 4.2168 13.8253 5.7918 13.8253 7.72513Z" />
      <path opacity="0.4" d="M13.8253 9V13.825C13.8253 15.7583 12.2503 17.3333 10.317 17.3333H4.17533C2.24199 17.3333 0.666992 15.7583 0.666992 13.825V9H13.8253Z" />
      <path d="M3.58301 3.26693C3.24134 3.26693 2.95801 2.98359 2.95801 2.64193V1.18359C2.95801 0.841927 3.24134 0.558594 3.58301 0.558594C3.92467 0.558594 4.20801 0.841927 4.20801 1.18359V2.64193C4.20801 2.99193 3.92467 3.26693 3.58301 3.26693Z" />
      <path d="M6.91699 3.26693C6.57533 3.26693 6.29199 2.98359 6.29199 2.64193V1.18359C6.29199 0.841927 6.57533 0.558594 6.91699 0.558594C7.25866 0.558594 7.54199 0.841927 7.54199 1.18359V2.64193C7.54199 2.99193 7.25866 3.26693 6.91699 3.26693Z" />
      <path d="M10.25 3.26693C9.90833 3.26693 9.625 2.98359 9.625 2.64193V1.18359C9.625 0.841927 9.90833 0.558594 10.25 0.558594C10.5917 0.558594 10.875 0.841927 10.875 1.18359V2.64193C10.875 2.99193 10.5917 3.26693 10.25 3.26693Z" />
      <path d="M17.0415 10.9336C17.0415 12.7253 15.5915 14.1753 13.7998 14.1753V7.68359C15.5831 7.68359 17.0415 9.14193 17.0415 10.9336Z" />
    </SvgIcon>
  );
}

// WiFiIcon
export function WiFiIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path opacity="0.4" d="M12.492 0.666016H5.51699C2.47533 0.666016 0.666992 2.47435 0.666992 5.50768V12.4827C0.666992 15.516 2.47532 17.3243 5.50866 17.3243H12.4837C15.517 17.3243 17.3253 15.516 17.3253 12.4827V5.50768C17.3337 2.47435 15.5253 0.666016 12.492 0.666016Z" />
      <path d="M14.0004 7.92448C13.8671 7.92448 13.7338 7.88281 13.6171 7.79115C10.8088 5.62448 7.18376 5.62448 4.38376 7.79115C4.10876 7.99948 3.7171 7.94948 3.50877 7.68281C3.30043 7.40781 3.35043 7.01615 3.6171 6.80781C6.88376 4.28281 11.1088 4.28281 14.3838 6.80781C14.6588 7.01615 14.7088 7.40781 14.4921 7.68281C14.3754 7.84115 14.1838 7.92448 14.0004 7.92448Z" />
      <path d="M12.6673 10.4987C12.534 10.4987 12.4006 10.457 12.284 10.3654C10.284 8.8237 7.70896 8.8237 5.70896 10.3654C5.43396 10.5737 5.04229 10.5237 4.83396 10.257C4.62563 9.99036 4.67563 9.59036 4.94229 9.38203C7.40063 7.48203 10.584 7.48203 13.0423 9.38203C13.3173 9.59036 13.3673 9.98203 13.1506 10.257C13.0423 10.4154 12.859 10.4987 12.6673 10.4987Z" />
      <path d="M10.8336 13.0749C10.7003 13.0749 10.567 13.0332 10.4503 12.9415C9.56697 12.2582 8.4253 12.2582 7.54197 12.9415C7.26697 13.1499 6.8753 13.0999 6.66697 12.8332C6.45863 12.5582 6.50864 12.1665 6.7753 11.9582C8.1003 10.9332 9.88364 10.9332 11.2086 11.9582C11.4836 12.1665 11.5336 12.5582 11.317 12.8332C11.2086 12.9915 11.0253 13.0749 10.8336 13.0749Z" />
    </SvgIcon>
  );
}

// MediaIcon
export function MediaIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
    <path opacity="0.4" d="M40,41H8c-2.2,0-4-1.8-4-4V11c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v26C44,39.2,42.2,41,40,41z"/>
    <path opacity="0.6" d="M35 13A3 3 0 1 0 35 19A3 3 0 1 0 35 13Z"/>
    <path opacity="0.6" d="M20 16L9 32 31 32z"/>
    <path opacity="1" d="M31 22L23 32 39 32z"/>
    </SvgIcon>
  );
}
