import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import overviewReducer from '../pages/dashboard/overview/overview';
import orginizationUsersReducer from '../pages/dashboard/organisation/OrganisationSlice';
import venueOnboardEditReducer from '../pages/dashboard/venues/VenuesSlice';
import spaceOnboardEditReducer from '../pages/dashboard/spaces/SpacesSlice';
import coworkCustomersReducer from '../pages/dashboard/customers/GeneralCustomersCoworkingSlice';
import spaceCreateEditReducer from '../pages/dashboard/customers/SpaceCreateEditSlice';
import calendarReducer from '../pages/dashboard/calendar/calendarSlice';
import settingsReducer from '../pages/settings/settingsSlice';


// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['venueOnboardEdit'],
};

const myPlacePersistConfig = {
  key: 'venueOnboardCreate',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout', 'venueOnboardEdit'],
};

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  overview: overviewReducer,
  settings: settingsReducer,
  coworkCustomers: coworkCustomersReducer,
  calendar: calendarReducer,
  spaceCreateEdit: spaceCreateEditReducer,
  orginization:orginizationUsersReducer,
  venueOnboardEdit: venueOnboardEditReducer,
  spaceOnboardEdit: spaceOnboardEditReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'settings/logoutResetReduxStates') {
    // for all keys defined in your persistConfig(s)
    // storage.removeItem('persist:root')
    window.localStorage.removeItem('redux-root')
    window.localStorage.removeItem('redux-myVenues')
    window.localStorage.removeItem('redux-product')
    window.localStorage.removeItem('redux-myVenues')

    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export { rootPersistConfig, rootReducer };
