import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {format, isValid, parse, parseISO} from 'date-fns';
import { useTheme } from '@mui/material/styles';
import {Grid, Box, Card, Rating, Typography, Stack, Avatar, Pagination, Container, Tabs, Tab} from '@mui/material';
import { useDispatch, useSelector } from '../../../redux/store';
import {getReviews, setCoworkId, setOrgId, updateDuration, updatePage} from './GeneralCustomersCoworkingSlice';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import { SkeletonTable } from '../../../components/skeleton';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
import Page from "../../../components/Page";
import DurationFilter from "../../../components/DurationFilter";
import useSettings from "../../../hooks/useSettings";

import {ROLE} from "../../../utils/constants";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../utils/axios";
import {URL_ORGANIZATION, URL_PLACES} from "../../../utils/restApiUrls";
import {RHFSelect} from "../../../components/hook-form";
import {BranchSpaceFilter} from "../calendar/sections";
import SkeletonReviews from "../../../components/skeleton/SkeletonReviews";

const CUSTOMER_BOX_WIDTH_DESKTOP = '150px';
const CUSTOMER_BOX_WIDTH_MOBILE = '70px';

export default function CustomerReviewsList() {
    const { themeStretch } = useSettings();
    const dispatch = useDispatch();
    const {user} = useAuth();
    const { Translate, translate: t } = useLocales();
    const [organisations, setOrganisations] = useState([]);
    const [venues, setVenues] = useState([]);
    const showOrgList = user?.system_user === ROLE.SUPER_USER || user?.system_user === ROLE.CUSTOMER_SUPPORT
  const { isLoading, isReviewsUpdating, coworkId,orgId,duration, page, totalPages, error } = useSelector(
    (state) => state.coworkCustomers
  );
  const { reviews } = useSelector((state) => state.coworkCustomers.customers);

  const handleChangePage = (event, newPage) => {
    dispatch(updatePage(newPage - 1));
  };

    const getSpaces = async (org_id, org_update = false) => {
        try {
            const { data: responseData } = await axios.get(URL_PLACES.venues.replace(":org_id", org_id));
            if (responseData.success) {
                const venues = responseData.data
                    ?.filter(item => item.cowork_id != null)
                    .map(item => ({ id: item.cowork_id, name: item.name ,is_active:item?.is_publish })) || [];

                if (venues.length > 0) {
                    const firstVenueId = venues[0].id;
                    if (org_update || !coworkId) {
                        dispatch(setCoworkId(firstVenueId));
                    }
                    setVenues(venues);
                }
            }
        } catch (error) {
            console.error("Error fetching spaces:", error);
        }
    };


    const getOrganisations = async() => {

        try {
            const response = await axios.get(URL_ORGANIZATION.organisationsList);
            if(response.data.success){
                setOrganisations(response.data.data)
            }

        } catch (error) {
            console.log(error,"error")
        }
    }

    const handleChangeDuration = (val) => {
        if (val !== 'custom') dispatch(updateDuration({ newDuration: val}));
    };

    const handleOrgChange = (value)=>{
         setVenues([])
        let oragnisation_id = value
        dispatch(setOrgId(oragnisation_id))
        getSpaces(oragnisation_id,true)
    }

    const handleVenueChange = (value)=>{
        let venue_id = value
        dispatch(setCoworkId(venue_id))
        // if(venue_id && venues?.length){
        //     venues.find((_branch) =>{
        //         if( _branch.id === venue_id ){
        //             setSpaces(_branch?.spaces) ;
        //         }
        //     });
        // }
    }


    useEffect(()=>{

        if(user?.system_user === ROLE.SUPER_USER || user?.system_user === ROLE.CUSTOMER_SUPPORT){
            getOrganisations()
            if(orgId){
                getSpaces(orgId);
            }
        }else{
            getSpaces(user?.organisation_user_details?.
                organisation?.id);
        }

    },[])

    useEffect(() => {
        if(coworkId){
            dispatch(getReviews({ page,per_page:5, durationLabel: duration,cowork_id:coworkId }));
        }

  }, [dispatch, page, duration,coworkId]);

  return (
      <Page title={t('customers.reviews')}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h4" sx={{ mb: 5, display: 'block' }}>
                      <Translate i18nKey="customers.reviews" />
                  </Typography>
                  {/*{duration  && (*/}
                  {/*    <DurationFilter*/}
                  {/*        init={duration}*/}
                  {/*        onChange={handleChangeDuration}*/}
                  {/*        // applyDateRange={handleChangeDuration}*/}
                  {/*        // custom={true}*/}
                  {/*    />*/}
                  {/*)}*/}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between',gap:'10px' }}>
                  {showOrgList ? <BranchSpaceFilter options={organisations?.length ? organisations : []} placeholder={'calendar.selectOrganization'} init={orgId} label={'Select Organization'}  minWidth={'300px'}  onChange={handleOrgChange} />
                      :null}

                  <BranchSpaceFilter options={venues?.length ? venues : []} label={'Select Venue'}  minWidth={'250px'} type={'organization'} init={coworkId} onChange={handleVenueChange} />
              </Box>
              </Box>

              <Grid item>
                      {isReviewsUpdating && <SkeletonReviews />}
                      {(!isReviewsUpdating && reviews && reviews.length && (
                          <Grid item xs={12}>
                              <Card sx={{ px: 1, py: 3 }}>
                                  <Scrollbar>
                                      <Stack spacing={5} sx={{ p: 3, pr: 0, mt: 2 }}>
                                          {reviews && reviews.map((review, index) => <ReviewItem key={index} review={review} />)}
                                      </Stack>
                                      <Pagination
                                          page={page + 1}
                                          count={totalPages}
                                          onChange={handleChangePage}
                                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                      />
                                  </Scrollbar>
                              </Card>
                          </Grid>
                      )) ||
                      ''}

                      {!isLoading && !isReviewsUpdating && reviews && !reviews.length && (
                          <Grid item xs={12}>
                              <Card sx={{ px: 1, py: 3 }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'center',alignItems:'center',height:'40vh'}}>
                                      <Typography variant="subtitle1" noWrap>
                                          <Translate i18nKey="customers.noReviews" />
                                      </Typography>
                                  </Box>
                              </Card>
                          </Grid>
                      )}
                  {(!coworkId && !reviews)  ? (
                      <Grid item xs={12}>
                          <Card sx={{ px: 1, py: 3 }}>
                              <Box sx={{ display: 'flex', justifyContent: 'center',alignItems:'center',height:'40vh'}}>
                          <Typography variant="subtitle1" noWrap>
                          <Translate i18nKey="Please select a venue from the dropdown above to view reviews related to that venue." />
                         </Typography>
                              </Box>
                          </Card>
                      </Grid>
                  ) : null}
                      {error && (
                          <Grid item xs={12}>
                              <Box sx={{ mb: 5, pb: 5 }}>{error}</Box>
                          </Grid>
                      )}
              </Grid>
          </Container>
      </Page>

  );
}

ReviewItem.propTypes = {
  review: PropTypes.object,
};

function ReviewItem({ review }) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');

  const reviewData = {
    avatarUrl: review?.customer_profile_image,
    name: `${review?.customer_user?.first_name} ${review?.customer_user?.last_name}`,
    branch: review?.branch?.branch,
    ago: review?.created,
    date: review?.created_at,
    rating: review?.rating,
    badges: review?.review_badges,
    review: review?.feedback,
  };

    let formattedDate = '-';

    if (reviewData.date) {
        try {

            // Attempt to parse with `parse` function
            const parsedDate = parseISO(reviewData.date);

            // Check if the parsed date is valid, otherwise fallback to ISO parsing
            if (isValid(parsedDate)) {
                formattedDate = format(parsedDate, 'd MMM yyyy');
            } else {
                console.warn("Invalid format detected, attempting ISO parsing.");
                const isoParsedDate = new Date(reviewData.date);
                if (isValid(isoParsedDate)) {
                    formattedDate = format(isoParsedDate, 'd MMM yyyy');
                }
            }
        } catch (error) {
            console.error("Date parsing error:", error, "Date value:", reviewData.date);
        }
    }

  return (
    <Stack direction="row" alignItems="flex-start" spacing={isDesktop ? 10 : 2}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: isDesktop ? CUSTOMER_BOX_WIDTH_DESKTOP : CUSTOMER_BOX_WIDTH_MOBILE,
          width: isDesktop ? CUSTOMER_BOX_WIDTH_DESKTOP : CUSTOMER_BOX_WIDTH_MOBILE,
        }}
      >
        <Avatar src={reviewData.avatarUrl} sx={{ width: '64px', height: '64px', mb: 1 }} />
        <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
          {reviewData.name}
        </Typography>
        <Typography variant="caption" title={formattedDate} sx={{ textAlign: 'center' }}>
          {reviewData.ago}
        </Typography>
      </Box>
      <Stack
        spacing={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: `calc(100% - ${isDesktop ? CUSTOMER_BOX_WIDTH_DESKTOP : CUSTOMER_BOX_WIDTH_MOBILE})`,
        }}
      >
        <Rating readOnly size="small" precision={0.5} name="reviews" value={review.rating} sx={{ mb: 1 }} />
        {!!reviewData?.badges?.length && (
          <Box sx={{ flexWrap: 'nowrap', justifyContent: 'flex-start', alignItems: 'center', mx: -2 }}>
            <>
              {reviewData.badges.map((badge) => (
                <Label
                  key={badge.id}
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  sx={{
                    p: 2,
                    mx: 1,
                    my: 1,
                    borderRadius: '20px',
                    fontSize: '0.875rem',
                    color: theme.palette.primary.darker,
                    backgroundColor: theme.palette.grey['200'],
                    fontWeight: 500,
                  }}
                >
                  {reviewData.rating <= 3 ? badge.bad : badge.good}
                </Label>
              ))}
            </>
          </Box>
        )}
        <Typography sx={{ mt: 1 }}>{reviewData.review}</Typography>
      </Stack>
    </Stack>
  );
}
