import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import {URL_CALENDAR, URL_PLACES, URL_BOOKING, URL_ORGANIZATION} from '../../../utils/restApiUrls';
//
import { dispatch } from '../../../redux/store';
import {BOOKING_TYPE} from "../../../utils/constants";

// ----------------------------------------------------------------------
const SPACES_FETCH_ERROR = 'calendar.error.fetchSpaces';
const ORGANIZATION_FETCH_ERROR = 'calendar.error.fetchOrg';
const NO_EVENTS_ERROR = 'calendar.error.fetchSpecificEvent';
const GENERAL_EVENTS_ERROR = 'calendar.error.fetchEvents';
const BT_CREATE_SUCCESS = 'calendar.success.blockTime'; // BT: Block TIme
const BT_CREATE_ERROR = 'calendar.error.blockTime'; // BT: Block TIme
const BT_UPDATE_SUCCESS = 'calendar.success.updateBlockTime'; // BT: Block TIme edit success
const BT_UPDATE_ERROR = 'calendar.error.updateBlockTime'; // BT: Block TIme edit error
const B_UPDATE_SUCCESS = 'calendar.success.updateBooking'; // Booking Update Success
const B_UPDATE_ERROR = 'calendar.error.updateBooking'; // Booking Update Error
const B_DELETE_SUCCESS = 'calendar.success.deleteBooking'; // Booking Delete Success
const B_DELETE_ERROR = 'calendar.error.deleteBooking'; // Booking Delete Error
const B_CANCEL_SUCCESS = 'calendar.success.cancelBooking'; // Booking Cancel Success
const B_CANCEL_ERROR = 'calendar.error.cancelBooking'; // Booking Cancel Error
// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    alertError: null,
    alertSuccess: null,
    events: [],
    isOpenModal: false,
    drawerVisibilty: false,
    selectedEventId: null,
    selectedRange: null,
    isLoadingSpace: false,
    selectedEvent:null,
    eventDetails:null,
    spaces: [],
    organisations: [],
    selectedBranch: null,
    selectedOrganization: null,
    selectedBranchId: null,
    selectedSpaceId: null,
    isEventUpdated: false,
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
            state.error = null;
        },

        // START REFRESHING EVENTS
        startRefreshing(state) {
            state.isEventUpdated = true;
            state.error = null;
        },

        setDrawerVisibilty(state,action) {
            state.drawerVisibilty = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // HAS ERROR
        setEmptyEventError(state, action) {
            state.isLoading = false;
            if(state.selectedBranch && state.selectedSpaceId){
                const messageText = action.payload;
                const branchName = state.selectedBranch.name;
                const spaceObj =  state.selectedBranch.spaces.find(x=>x?.id===state?.selectedSpaceId);
                const spaceName = spaceObj.name;
                state.error = messageText.replace('branchname',branchName).replace('spacename', spaceName);
            }
        },

        // Display Alert Success
        setAlertSuccess(state, action) {
            const message = action.payload;
            state.alertSuccess = message;
            state.isLoading = false;
        },

        // Display Alert Error
        setAlertError(state, action) {
            const message = action.payload;
            state.alertError = message;
            state.isLoading = false;
        },

        // Reset Alert Success
        resetAlertSuccess(state) {
            state.alertSuccess = null;
            state.isLoading = false;
        },

        // Reset Alert Error
        resetAlertError(state) {
            state.alertError = null;
            state.isLoading = false;
        },

        // GET EVENTS
        getEventsSuccess(state, action) {
            state.isLoading = false;
            state.isEventUpdated = false;
            const _events = (action.payload).map(x=>{
                /*
                '#00AB55', // theme.palette.primary.main,
                '#1890FF', // theme.palette.info.main,
                '#54D62C', // theme.palette.success.main,
                '#FFC107', // theme.palette.warning.main,
                '#FF4842', // theme.palette.error.main
                '#04297A', // theme.palette.info.darker
                '#7A0C2E', // theme.palette.error.darker
                */
                let textColorHex = '#1890FF'; // Credit - blue
                textColorHex = x.type == BOOKING_TYPE?.BOOKING ? '#00AB55' : textColorHex; // Paid - green
                textColorHex = x.type == BOOKING_TYPE?.BLOCK_DATE ? '#FF4842' : textColorHex; // Blocked - red
                textColorHex = x?.detail?.is_external ? '#FFC107' : textColorHex; // Blocked - red
                // textColorHex = x.className === "theme-meeting-pumpkin" || x.type != BOOKING_TYPE?.BLOCK_DATE ? '#FFC107' : textColorHex; // Google - yellow
                /*
                 * className
                 * MEET / OFFICE / CREATIVE - theme-meeting-purple
                 * Letswork Block - theme-meeting-block
                 * Google Calender Block - theme-meeting-pumpkin
                 */
                // let textColorHex = '#1890FF'; // Credit - blue
                // textColorHex = !x.credit_used ? '#00AB55' : textColorHex; // Paid - green
                // textColorHex =  ? '#FF4842' : textColorHex; // Blocked - red
                const title = x.type == BOOKING_TYPE?.BLOCK_DATE ? `Blocked ${x?.detail?.block_reason ? `(${x?.detail?.block_reason})` :''}` : x.detail?.venue_booking?.event_name;
                const editable = x.type == BOOKING_TYPE?.BLOCK_DATE ? true :false;

                return {
                    ...x,
                    // internal: x.className === 'theme-meeting-block',
                    id:x?.detail?.id,
                    textColor: textColorHex,
                    start: `${x.detail.start_date}T${x.detail.start_time}`,
                    end: `${x.detail.end_date}T${x.detail.end_time}`,
                    type: x?.type,
                    editable,
                    title,
                }
            })
            state.events = _events;
        },

        // CREATE EVENT
        createEventSuccess(state, action) {
            const newEvent = action.payload;
            state.isLoading = false;
            state.events = [...state.events, newEvent];
        },

        // UPDATE EVENT
        updateEventSuccess(state, action) {
            const event = action.payload;
            const updateEvent = state.events.map((_event) => {
                if (_event.id === event.id) {
                    return event;
                }
                return _event;
            });

            state.isLoading = false;
            state.events = updateEvent;
        },

        // CANCEL EVENT
        cancelEventSuccess(state, action) {
            const { eventId } = action.payload;
            // const deleteEvent = state.events.filter((event) => event.id !== eventId);
            // state.events = deleteEvent;
        },

        // DELETE EVENT
        deleteEventSuccess(state, action) {
            const { eventId } = action.payload;
            const deleteEvent = state.events.filter((event) => event.id !== eventId);
            state.events = deleteEvent;
            state.isOpenModal = false;
        },

        // SELECT EVENT
        selectEvent(state, action) {
            const eventId = action.payload; // CONVERT TO INTEGER

            if (eventId) {
                const selectedEvent = state.events.find((event) => event?.id === eventId);
                state.selectedEventId = selectedEvent?.id; // If you still need the ID
                state.selectedEvent = selectedEvent; // Store the entire event object
            } else {
                state.selectedEventId = null;
                state.selectedEvent = null;
            }
        },

        setEventDetails(state, action) {
            const event = action.payload;
               state.eventDetails = event

        },
        // SELECT RANGE
        selectRange(state, action) {
            const { start, end } = action.payload;
            state.isOpenModal = true;
            state.selectedRange = { start, end };
        },

        // START LOADING SPACES
        startLoadingSpace(state) {
            state.isLoadingSpace = true;
            state.selectedBranch = null;
            state.selectedBranchId = null;
            state.selectedSpaceId = null;
        },

        // GET BRANCH AND SPACE INFO
        getSpaceSuccess(state, action) {
            state.isLoadingSpace = false;
            const _venues = action.payload;
            state.spaces = _venues.slice();
            state.selectedBranch = _venues[0] || null;
            state.selectedBranchId = _venues[0]?.id || null;
            state.selectedSpaceId = _venues[0]?.spaces[0]?.id || null;
            // getEvents(state.selectedSpaceId);
        },



        //
        organisationsListSuccess(state, action) {
            const organizations = action.payload
            state.isLoading = false;
            state.organisations = organizations;
            state.selectedOrganization = organizations?.length ? organizations[0]?.id : null;
        },

        // SELECT BRANCH
        selectBranch(state, action) {

            const branchId = action.payload;

            if(branchId){
                const _spaces = state.spaces.slice();

                // const _selectedSpace =
                _spaces.find((_branch) =>{
                    if( _branch.id === branchId ){
                        state.selectedBranchId = branchId;
                        state.selectedBranch = _branch;
                        // assuming all branches would have children
                        if(_branch?.spaces?.length){
                            state.selectedSpaceId = _branch?.spaces[0]?.id;
                            // getEvents();
                        }else{
                            state.selectedSpaceId = null;
                            state.events = []
                        }

                    }
                    return _branch.id === branchId;
                });
            }else{
                state.selectedBranchId = null;
                state.selectedBranch = null;
                state.events = [];
            }

        },

        // SELECT SPACE
        selectSpace(state, action) {
            const spaceId = action.payload;
            state.selectedSpaceId = spaceId;

        },

        selectOrganization(state, action) {
            const org_id = action.payload;
            state.selectedOrganization = org_id;

        },

        // OPEN MODAL
        openModal(state) {
            state.isOpenModal = true;
        },

        // CLOSE MODAL
        closeModal(state) {
            state.isOpenModal = false;
            state.selectedEventId = null;
            state.selectedRange = null;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal,setEventDetails,startRefreshing, selectEvent,setDrawerVisibilty,selectOrganization, selectBranch, selectSpace, resetAlertSuccess, resetAlertError ,setAlertError,hasError} = slice.actions;

// ----------------------------------------------------------------------

export function getEvents(spaceId, start_date, end_date) {
    if(!spaceId) return {}; // Abort if no space information available
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            // const response = await axios.get(URL_CALENDAR.events, { params: { spaces: spaceId.toString(), start, end } });
            const response = await axios.post(URL_CALENDAR.bookings.replace(':space_id',spaceId.toString()), {   start_date, end_date });
            if(response.data.status){
                dispatch(slice.actions.getEventsSuccess(response.data.data));
                if(!response.data.length) dispatch(slice.actions.setEmptyEventError(NO_EVENTS_ERROR));
            } else {
                dispatch(slice.actions.hasError(response.data.error?.message || GENERAL_EVENTS_ERROR));
                dispatch(slice.actions.setAlertError(response.data.error?.message || GENERAL_EVENTS_ERROR));
            }

        } catch (error) {
            // dispatch(slice.actions.setEmptyEventError(NO_EVENTS_ERROR));
            dispatch(slice.actions.setEmptyEventError(error.response?.data?.message));
        }
    };
}
// ----------------------------------------------------------------------

export function refreshEvents(spaceId, start_date, end_date) {
    return async () => {
        try {
            // const response = await axios.get(URL_CALENDAR.events, { params: { spaces: space.id.join(',') } });
            const response = await axios.post(URL_CALENDAR.bookings.replace(':space_id',spaceId.toString()), {   start_date, end_date });

            if(response.data.status){
                dispatch(slice.actions.getEventsSuccess(response.data.data));
                if(!response.data.length) dispatch(slice.actions.setEmptyEventError(NO_EVENTS_ERROR));
            } else {
                dispatch(slice.actions.hasError(response.data.error?.message || GENERAL_EVENTS_ERROR));
                dispatch(slice.actions.setAlertError(response.data.error?.message || GENERAL_EVENTS_ERROR));
            }
        } catch (error) {
            // dispatch(slice.actions.setEmptyEventError(NO_EVENTS_ERROR));
            dispatch(slice.actions.setEmptyEventError(error.response?.data?.message));
        }
    };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent) {
    return async () => {
        // dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URL_CALENDAR.createblock, newEvent);
            if(response.data.status){
                dispatch(slice.actions.startRefreshing());
                dispatch(slice.actions.setAlertSuccess(BT_CREATE_SUCCESS));
                dispatch(slice.actions.closeModal());
            } else {
                dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || BT_CREATE_ERROR));
            }
        } catch (error) {
            // dispatch(slice.actions.setAlertError(BT_CREATE_ERROR));
            console.log(error,'error')
            dispatch(slice.actions.setAlertError(error.response?.data?.message));
        }
    };
}

// ----------------------------------------------------------------------

export function editBlockEvent(newEvent,id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(URL_CALENDAR.updateblock.replace(":id",id), newEvent);
            if(response.data.status){
                dispatch(slice.actions.closeModal());
                dispatch(slice.actions.startRefreshing());
                dispatch(slice.actions.setAlertSuccess(BT_UPDATE_SUCCESS));
            } else {
                dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || BT_UPDATE_ERROR));
            }
        } catch (error) {
            // dispatch(slice.actions.setAlertError(BT_UPDATE_ERROR));
            dispatch(slice.actions.setAlertError(error.response?.data?.message));
        }
    };
}

// ----------------------------------------------------------------------

export function updateBooking(eventId, updateEvent) {
    const apiUrl = URL_BOOKING.updateBooking.replace(':id', eventId);
    return async () => {
        try {
            const response = await axios.put(apiUrl, {
                ...updateEvent,
            });
            if(response.data.status){
                dispatch(setDrawerVisibilty(false))
                dispatch(slice.actions.startRefreshing());
                dispatch(slice.actions.setAlertSuccess(B_UPDATE_SUCCESS));
            } else {
                dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || B_UPDATE_ERROR));
            }

        } catch (error) {
            console.log(error,'error')
            dispatch(setDrawerVisibilty(false))
            dispatch(slice.actions.setAlertError(B_UPDATE_ERROR));
            dispatch(slice.actions.hasError(B_UPDATE_ERROR));
        }
    };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updateEvent, spaceType) {
    const apiUrl = URL_CALENDAR.update.replace('space_type', (spaceType || 'meeting-rooms'));
    return async () => {
        try {
            const response = await axios.post(apiUrl, {
                ...updateEvent,
            });
            if(response.data.status){
                dispatch(setDrawerVisibilty(false))
                dispatch(slice.actions.startRefreshing());
                dispatch(slice.actions.setAlertSuccess(B_UPDATE_SUCCESS));
            } else {
                dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || B_UPDATE_ERROR));
            }

        } catch (error) {
            console.log(error,'error')
            dispatch(slice.actions.setAlertError(B_UPDATE_ERROR));
            dispatch(slice.actions.hasError(B_UPDATE_ERROR));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
    return async () => {
        // dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(URL_CALENDAR.deleteblock.replace(":id",eventId));
            if(response.status === 204){
                dispatch(slice.actions.selectEvent(null));
                dispatch(slice.actions.deleteEventSuccess({ eventId }));
                dispatch(slice.actions.setAlertSuccess(B_DELETE_SUCCESS));
            } else {
                dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || B_DELETE_ERROR));
            }

        } catch (error) {
            dispatch(slice.actions.setAlertError(B_DELETE_ERROR));
        }
    };
}

// ----------------------------------------------------------------------

export function cancelEvent(eventId, reason) {
    return async () => {
        // dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URL_BOOKING.cancelBooking.replace(":id",eventId), { order_id: eventId ,reason});
            if(response.data.status){
                dispatch(setDrawerVisibilty(false))
                dispatch(slice.actions.startRefreshing());
                dispatch(slice.actions.setAlertSuccess(response.data.message || B_CANCEL_SUCCESS));
            } else {
                dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || B_CANCEL_ERROR));
            }

        } catch (error) {
            dispatch(setDrawerVisibilty(false))
            dispatch(slice.actions.setAlertError(B_CANCEL_ERROR));
        }
    };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
    return async () => {
        dispatch(
            slice.actions.selectRange({
                start: start.getTime(),
                end: end.getTime(),
            })
        );
    };
}

// ----------------------------------------------------------------------

export function getSpaces(org_id) {
    return async () => {
        dispatch(slice.actions.startLoadingSpace());
        try {
            const response = await axios.get(URL_PLACES.conciseVenues.replace(':org_id',org_id));
            if(response.data.success){
                dispatch(slice.actions.getSpaceSuccess(response.data.data));
                if(!response.data.data.length) dispatch(slice.actions.hasError(SPACES_FETCH_ERROR));
            } else {
                dispatch(slice.actions.hasError(response.data.error?.message || response.data.error?.title || SPACES_FETCH_ERROR));
                dispatch(slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || SPACES_FETCH_ERROR));
            }

        } catch (error) {
            dispatch(slice.actions.hasError(SPACES_FETCH_ERROR));
        }
    };
}



export function getOrganisations() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(URL_ORGANIZATION.organisationsList);
            const { status, success, data } = response.data;
            if (success) {
                dispatch(slice.actions.organisationsListSuccess(data))
                if(data?.length){
                    let spaceId = data[0]?.id
                    dispatch(getSpaces(spaceId));
                }
            }
        } catch (error) {
            dispatch(slice.actions.hasError(ORGANIZATION_FETCH_ERROR));
        }
    }
}